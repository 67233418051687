import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Button, Col, Row } from "reactstrap";
import { MdAdd } from "react-icons/md";

import SelectFilter from "../SelectFilter";
import { OptionValue } from "../SelectFilter/types";
import CampaignPlatformForm from "../CampaignPlatformForm";
import CampaignPlatformsCardsHeader from "../CampaignPlatformsCardsHeader";
import CampaignPlatformCard from "../CampaignPlatformCard";
import { formatUri } from "../../App";
import {
  GET_ALL_PLATFORMS,
} from "../../Views/Configuration/connection";
import { platform } from "../Platforms";
import { showCustomNotification } from "../../Utils/notifications";
import { campaign } from "../../Views/Campaigns";
import { campaignPlatform } from "../CampaignForm";

export interface CtgtClient extends OptionValue {
  Campaigns: OptionValue[];
}

interface CampaignPlatformsProps {
  setShowCustomAlert: (value: React.SetStateAction<boolean>) => void;
  setCustomAlertMessage: (value: string) => void;
  setCustomAlertColor: (value: string) => void;
  selectedCampaign: campaign;
  campaignPlatforms: campaignPlatform[];
  setCampaignPlatforms: (value: React.SetStateAction<campaignPlatform[]>) => void;
}

export default function CampaignPlatforms({
  setShowCustomAlert,
  setCustomAlertMessage,
  setCustomAlertColor,
  selectedCampaign,
  campaignPlatforms,
  setCampaignPlatforms
}: CampaignPlatformsProps) {
  const { token } = useSelector((state: any) => state);

  const e_GetPlatforms = useQuery(GET_ALL_PLATFORMS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  const [campaignPlatformformShow, setcampaignPlatformformShow] =
    useState(false);

  const [platforms, setPlatforms] = useState<platform[]>([]);
  const [campaignPlatformToEdit, setCampaignPlatformToEdit] =
    useState<campaignPlatform>({} as campaignPlatform);

  const [selectedPlatform, setSelectedPlatform] = useState<platform>();
  const [selectedClientCampaignPlatforms, setSelectedClientCampaignPlatforms] =
    useState<campaignPlatform[]>([]);

  const selectedClient = selectedCampaign.clientId;
  const selectedCampaignId = selectedCampaign.id;

  useEffect(() => {
    if (e_GetPlatforms.data?.GetAllPlatforms?.state === "GET_ALL_PLATFORMS") {
      setPlatforms(e_GetPlatforms.data.GetAllPlatforms.platforms);
    }
  }, [e_GetPlatforms.data]);

  useEffect(() => {
    if (selectedCampaignId && selectedClient && selectedPlatform) {
      setSelectedClientCampaignPlatforms([]);
      const campaignPlatformsSelected = campaignPlatforms.filter(
        (campaignPlatform) =>
          campaignPlatform.campaignId === selectedCampaignId &&
          campaignPlatform.platformId === selectedPlatform.id &&
          campaignPlatform.clientId === selectedClient
      );

      setSelectedClientCampaignPlatforms(campaignPlatformsSelected);
    }
  }, [selectedCampaignId, selectedClient, selectedPlatform, campaignPlatforms]);

  const campaignPlatformhandleClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setCampaignPlatformToEdit({} as campaignPlatform);
    setcampaignPlatformformShow((current) => !current);
  };

  const handleSelectChange = () => {
    // Realiza las acciones necesarias al cambiar el valor en el SelectFilter

    // Cierra el formulario si estaba abierto previamente
    setcampaignPlatformformShow(false);
  };

  const handleUpdateCampaignPlatform = (
    updatedCampaignPlatform: campaignPlatform
  ) => {
    // Buscar el índice en la lista actual
    const updatedIndex =
      campaignPlatforms &&
      campaignPlatforms.findIndex(
        (campaignPlatform) => campaignPlatform.id === updatedCampaignPlatform.id
      );

    if (updatedIndex !== -1) {
      // Si existe en la lista, reemplazarlo con los datos actualizados
      const updatedCampaignPlatforms = [...campaignPlatforms];
      updatedCampaignPlatforms[updatedIndex] = updatedCampaignPlatform;
      setCampaignPlatforms(updatedCampaignPlatforms);

      showCustomNotification(
        "Plataforma por campaña actualizada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } else {
      // Si no existe en la lista, agregarlo como uno nuevo
      setCampaignPlatforms((prevCampaignPlatforms) => [
        ...prevCampaignPlatforms,
        updatedCampaignPlatform,
      ]);
      showCustomNotification(
        "Plataforma por campaña creada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  // Para actualizar la lista después de eliminar
  const updateCampaignPlatformList = (
    updatedCampaignPlatforms: campaignPlatform[]
  ) => {
    setCampaignPlatforms(updatedCampaignPlatforms);
  };

  const deleteCampaignPlatformConfirmation = (
    id: number | undefined,
    apiKey: string
  ) => {
    let isDelete = window.confirm(`¿Estás seguro de eliminar '${apiKey}'?`);

    if (isDelete) {
      deleteCampaignPlatform(id);
    } else {
      return;
    }
  };

  //Eliminación de plataformas por camṕañas
  const deleteCampaignPlatform = async (
    campaignPlatformId: number | undefined
  ) => {
    try {
      if (!campaignPlatformId) {
        throw new Error("ID de la plataforma por campaña no válido");
      }

      const apiUrl = `${formatUri("http")}/campaingplatforms-api`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: campaignPlatformId }),
      });

      if (!response.ok) {
        throw new Error("Error al eliminar la plataforma");
      }
      const updatedPlatforms = campaignPlatforms.filter(
        (campaignPlatform) => campaignPlatform.id !== campaignPlatformId
      );
      updateCampaignPlatformList(updatedPlatforms);
      scrollToTop();
      showCustomNotification(
        "Plataforma por campaña eliminada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } catch (error) {
      console.error("Error al eliminar la plataforma por campaña:", error);
      scrollToTop();
      showCustomNotification(
        "Error al eliminar la plataforma por campaña",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  return (
    <>
      <Row className="mt-4">
        {/* <h6>Plataformas por campaña</h6> */}
        <Col md={6} className="mb-1">
          <SelectFilter
            id="platform"
            label="Plataforma"
            placeholder="Selecciona de la lista..."
            options={platforms && platforms}
            setSelectedFormat={(value: any) => {
              const _platform = platforms.find(
                (p: any) => p.id === Number(value)
              );
              setSelectedPlatform(_platform); // Actualizar el estado con la plataforma seleccionada
              handleSelectChange();
            }}
          />
        </Col>
        <Col md={6}>
          <div className="d-flex flex-row-reverse">
            {selectedClient &&
              selectedCampaignId &&
              selectedPlatform &&
              selectedClientCampaignPlatforms.length === 0 && (
                <Button
                  className="Configuration__New FormFilter__Buttons__Submit mt-1"
                  color="dark"
                  outline
                  onClick={campaignPlatformhandleClick}
                >
                  Agregar
                  <MdAdd />
                </Button>
              )}
          </div>
        </Col>
      </Row>
      <div>
        {campaignPlatformformShow && (
          <CampaignPlatformForm
            campaignPlatformToEdit={campaignPlatformToEdit}
            setCampaignPlatformToEdit={setCampaignPlatformToEdit}
            setcampaignPlatformformShow={setcampaignPlatformformShow}
            handleUpdateCampaignPlatform={handleUpdateCampaignPlatform}
            selectedClient={selectedClient}
            selectedCampaignId={selectedCampaignId}
            selectedPlatform={selectedPlatform}
          />
        )}
      </div>
      {campaignPlatforms &&
        !campaignPlatformformShow &&
        selectedClientCampaignPlatforms.map((campaignPlatform) => {
          return (
            <>
              <CampaignPlatformsCardsHeader />
              <CampaignPlatformCard
                key={campaignPlatform.id}
                campaignPlatform={campaignPlatform}
                setcampaignPlatformformShow={setcampaignPlatformformShow}
                setCampaignPlatformToEdit={setCampaignPlatformToEdit}
                deleteCampaignPlatformConfirmation={
                  deleteCampaignPlatformConfirmation
                }
              />
            </>
          );
        })}
    </>
  );
}
