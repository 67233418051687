import { client } from "../../Views/Clients";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";

import { MdClear, MdMoreHoriz } from "react-icons/md";

import "./index.scss";

type Props = {
  client: {
    id?: number;
    name: string;
    description?: string;
    rut?: string;
    logoUrl: string;
    color?: string;
    abbr?: string;
    active?: boolean;
  };
  setDataToEdit?: (client: client) => void;
  deleteData?: (id: number | undefined, name: string) => void;
  setFormShow?: (value: React.SetStateAction<boolean>) => void;
};

export default function ClientCard({
  client,
  setDataToEdit,
  deleteData,
  setFormShow,
}: Props) {
  let { name, logoUrl, color } = client;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDataToEdit && setDataToEdit(client);
    setFormShow && setFormShow((current) => !current);
    scrollToTop();
  };

  const handleClientCardClick = (client: client) => {
    history.push(`/campaigns/${client.id}`);
  };

  const handleDelete = () => {
    // Llama a la función deleteData del componente padre (Clients)
    deleteData && deleteData(client.id, client.name);
  };

  return (
    <Card style={{ border: `4px solid ${color}` }} className="ClientCard__Card">
      <div className="d-flex justify-content-between ClientCard__Card__Buttons">
        {setDataToEdit && (
          <Button
            className="ClientCard__Card__Link d-flex fs-4 border-0 px-2"
            onClick={handleClick}
            outline
            color="secondary"
          >
            <MdMoreHoriz />
          </Button>
        )}

        {deleteData && (
          <Button
            className="ClientCard__Card__Link d-flex fs-5 border-0 px-2"
            onClick={handleDelete}
            outline
            color="secondary"
          >
            <MdClear />
          </Button>
        )}
      </div>
      <Button
        color="link"
        className="p-0"
        onClick={() => handleClientCardClick(client)}
      >
        <div className="ClientCard__Card__Img d-flex justify-content-center align-items-center">
          <img alt={name && name} src={logoUrl && logoUrl} />
        </div>
        <CardBody className="ClientCard__Card__Body d-flex align-items-end justify-content-center">
          <CardTitle className="ClientCard__Card__Title">
            {name && name}
          </CardTitle>
        </CardBody>
      </Button>
    </Card>
  );
}
