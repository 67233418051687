import { Button } from "reactstrap";
import { FcGoogle } from 'react-icons/fc'
import { GoogleButtonProps } from "./types";

import './index.scss'

import { formatUri } from "../../App";

export default function GoogleButton ({ label = 'Iniciar con tu cuenta Contingente' }: GoogleButtonProps) {
  const redirectGoogleSSO = () => {
    window.open(`${formatUri('http')}/auth/google`)
  }

  return <Button className='GoogleButton' block outline size='md' onClick={redirectGoogleSSO}>
    <FcGoogle /> &nbsp;{label}
  </Button>
}