// Librerías externas
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// Librerías internas
import useMobile from '../Hooks/useMobile'

// Librerías locales de la misma carpeta (`Routes`)
import { PrivateRoute, PublicRoute } from './'

// Vistas de la aplicación
import Login from '../Views/Login'
import RequestAccess from '../Views/RequestAccess'
import Home from '../Views/Home/'
import Clients from '../Views/Clients'
// import CreateCampaign from '../Views/CreateCampaign/'

// import Funnel from '../Views/Funnel/'
// import Ads from '../Views/Ads'
import Campaigns from '../Views/Campaigns'
import ConfigurationView from '../Views/Configuration'

export default function ContingenteRouter () {
  const { ViewportClass } = useMobile()

  return (
    <Router>
      <div className={`App ${ViewportClass}`}>
        <Switch>
          {/* Public Routes */}
          <PublicRoute restricted component={Login} path='/' exact />
          <PublicRoute restricted component={RequestAccess} path='/request-access' exact />
          {/* Private Routes */}
          <PrivateRoute restricted component={Home} path='/home' exact />
          <PrivateRoute restricted component={Clients} path='/clients' />
          {/* <PrivateRoute restricted component={Campaigns} path='/campaigns' /> */}
          <PrivateRoute path="/campaigns/:clientId" component={Campaigns} />
          <PrivateRoute restricted component={ConfigurationView} path='/settings' />
          {/* <PrivateRoute restricted component={CreateCampaign} path='/client/campaign/new' exact /> */}
          {/* <PrivateRoute restricted component={Funnel} path='/funnel' exact /> */}
          {/* <PrivateRoute restricted component={Ads} path='/ads' exact /> */}
        </Switch>
      </div>
    </Router>
  )
}
