

const initialState: any = {
    token: null,
    isLogged: false,
    userInfo: {},
    lastUpdate: 'desconocido'
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'LOGIN_USER':
            return updateState(state, {
                ...action.payload    
            });
        case 'LOGOUT':
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
        case 'EXTERNAL_LOGIN':
            return updateState(state, {
                ...action.payload
            })
        case 'GET_USER_DATA':
            return updateState(state, {
                ...action.payload
            })
        /* case BLOCK_NAVIGATION:
            return updateState(state, {
                blockNavigation: action.payload
            })*/

    }
    return state
}

export default rootReducer