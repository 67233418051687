export function externalLogin({ token }: any) {
    return (dispatch: any) => {
        dispatch({
            type: 'EXTERNAL_LOGIN',
            payload: {
                token,
                isLogged: true,
                userInfo: {}
            }
        })
    }
}

export function getUserData({ user, refreshToken, lastUpdate }: any) {
    return (dispatch: any) => {
        dispatch({
            type: 'GET_USER_DATA',
            payload: {
                userInfo: {
                    ...user,
                    fullName: `${user.firstName} ${user.lastName}`
                },
                token: refreshToken,
                isLogged: true,
                lastUpdate
            }
        })
    }
}

export function attemptLogin({ token, userdata }: any){
    // const decodedToken: any = jwt_decode(token)
    const isLogged = true

    return (dispatch: any) => {
            dispatch({ 
                type: 'LOGIN_USER',
                payload: {
                    token,
                    userInfo: {
                        ...userdata,
                        fullName: `${userdata.firstName} ${userdata.lastName}`
                    },
                    isLogged
                }
            })
            return isLogged
    }
}

export function logout(payload: any){
    return { type: 'LOGOUT', payload }
}
