import { gql } from "@apollo/client";

export const GET_PERFORMANCE_DATE = gql`
  query GetPerformanceDate {
    GetPerformanceDate {
      state
      performanceDates {
        campaignId
        oldestPerformanceDate
      }
    }
  }
`;
