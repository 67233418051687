import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import useMessage from '@rottitime/react-hook-message-event'

import './index.scss'
import useMobile from '../../Hooks/useMobile';
import { Link } from 'react-router-dom';
import { LogoContingente } from '../../Assets';

import { GoogleButton, Hr } from '../../Components';
import { useDispatch } from 'react-redux';
import { externalLogin } from '../../Store/Actions';

export default function Login() {
  const { isMobile } = useMobile()
  const dispatch = useDispatch()

  // useEffect(() => {
  //   window.addEventListener("message", (event) => {
  //     // Do we trust the sender of this message?  (might be
  //     // different from what we originally opened, for example).

  //     // event.source is popup
  //     // event.data is "hi there yourself!  the secret response is: rheeeeet!"
  //       console.log(event.source);
  //       console.log(event.data);
  //   }, false);

  // }, [])

  useMessage('external-login', (send: any, payload: any) => {
    console.log({ payload })
    dispatch(externalLogin(payload))
  })


  return (
    <Container fluid className='Login'>
      <Row>
        <Col className='Login__Background' hidden={isMobile} xs={1} md={6} />

        <Col className='Login__Form' xs={12} md={6}>
          <Container fluid>
            <Row className='Login__MainRow'>
              <Col md={{
                size: 8,
                offset: 2
              }} className='Login__Form__Col'>
                <LogoContingente className='Login__Form__Col__Logotipo' />
                <h2>Iniciar Sesión en <strong>Reportes</strong></h2>
                <p>Para utilizar esta herramienta, debes iniciar sesión con tu cuenta <strong>Contingente</strong>. Si eres cliente, puedes ingresar con tu código y clave compartida.</p>

                <GoogleButton />
                <Hr text='ó' />
                <Form>
                  <FormGroup>
                    <Label for="clientCode">
                      Código Cliente
                    </Label>
                    <Input
                      id="clientCode"
                      name="clientCode"
                      placeholder="CTGT_CAR1"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="clientPassword">
                      Clave Contingente
                    </Label>
                    <Input
                      id="clientPassword"
                      name="clientPassword"
                      placeholder="******"
                      type="password"
                    />
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup check>
                        <Input type="checkbox" name='clientRememberMe' id='clientRememberMe' />
                        {' '}
                        <Label check for='clientRememberMe'>
                          Recuérdame
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <Link to={'/request-access'}>¿Necesitas acceso?</Link>
                    </Col>
                  </Row>
                  <Button block color='danger' size='lg'>
                    Iniciar Sesión
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}
