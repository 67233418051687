import { gql } from "@apollo/client";

export const GET_ALL_CLIENTS = gql`
  query GetAllClients {
    GetAllClients {
      state
      clients {
        id
        name
        abbr
        description
        rut
        logoUrl
        color
        active
      }
    }
  }
`;
