import { format } from "date-fns";
import es from "date-fns/esm/locale/es/index.js";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import {
  AccordionItem,
  AccordionHeader,
  Table,
  AccordionBody,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
} from "reactstrap";
export interface CardItemProps {
  showOnlyInvestment?: boolean;
  client: {
    name: string;
    id: string;
    investment: number;
    investmentPercentage: number;
    investmentComparission: number;
    sessions: number;
    sessionsPercentage: number;
    sessionsComparission: number;
    costSession: number;
    costSessionPercentage: number;
    costSessionComparission: number;
    events: number;
    eventsPercentage: number;
    eventsComparission: number;
    costEvent: number;
    costEventPercentage: number;
    costEventComparission: number;
    table: {
      name: string;
      fields: {
        name: string;
        value: string;
        formattedValue?: string;
      }[];
    }[];
  };
}

interface CostType {
  cost: number;
  costType?: string;
}

interface PercentageArrowType extends Partial<CostType> {
  percentage: number;
  comparission: number;
  prefix?: string;
}

const PercentageArrow = ({
  percentage,
  comparission,
  prefix = "",
}: PercentageArrowType) => {
  const personalId = `lastPeriod${Math.round(
    Math.abs(percentage)
  )}${comparission}`;

  const formattedVal = Intl.NumberFormat("es-CL");
  const _value = formattedVal.format(Number(comparission));

  const RenderItem = ({ personalId }: { personalId: string }) => {
    if (percentage > 0) {
      return (
        <div
          id={personalId}
          className="p-0 fs-6 text-success DashboardCards__Percentage"
        >
          <FaArrowUp className="DashboardCards__IconArrow" />
          {percentage}%
        </div>
      );
    }

    return (
      <div
        id={personalId}
        className="p-0 fs-6 text-danger DashboardCards__Percentage"
      >
        <FaArrowDown className="DashboardCards__IconArrow" />
        {percentage}%
      </div>
    );
  };

  return (
    <>
      <UncontrolledTooltip placement="top" target={personalId}>
        {prefix}
        {_value} del periodo anterior.
      </UncontrolledTooltip>
      <RenderItem personalId={personalId} />
    </>
  );
};

const CostPercentage = ({
  percentage,
  comparission,
  prefix = "",
}: PercentageArrowType) => {
  const personalId = `lastPeriod${Math.round(
    Math.abs(percentage)
  )}${comparission}`;

  const formattedVal = Intl.NumberFormat("es-CL");
  const _value = formattedVal.format(Number(comparission));

  const RenderItem = ({ personalId }: { personalId: string }) => {
    if (percentage > 0) {
      return (
        <div
          id={personalId}
          className="p-0 fs-6 text-danger DashboardCards__Percentage"
        >
          <FaArrowUp className="DashboardCards__IconArrow" />
          {percentage}%
        </div>
      );
    }

    return (
      <div
        id={personalId}
        className="p-0 fs-6 text-success DashboardCards__Percentage"
      >
        <FaArrowDown className="DashboardCards__IconArrow" />
        {percentage}%
      </div>
    );
  };

  return (
    <>
      <UncontrolledTooltip placement="top" target={personalId}>
        {prefix}
        {_value} del periodo anterior.
      </UncontrolledTooltip>
      <RenderItem personalId={personalId} />
    </>
  );
};

export default function CardItem({
  client: {
    name,
    id,
    investment,
    investmentPercentage,
    investmentComparission,
    sessions,
    sessionsPercentage,
    sessionsComparission,
    costSession,
    costSessionPercentage,
    costSessionComparission,
    events,
    eventsPercentage,
    eventsComparission,
    costEvent,
    costEventPercentage,
    costEventComparission,
    table,
  },
  showOnlyInvestment = false,
}: CardItemProps) {
  if (showOnlyInvestment && investment === 0) {
    return null;
  }

  // console.log(table);

  // Array de nombres de columna en el orden deseado
  const columnNames = [
    "Sesiones",
    "Costo por Sesión",
    "Eventos",
    "",
    "Inversión Meta",
    "Inversión Search",
    "Inversión Display",
  ];

  let performanceFormat = false;

  // Función para encontrar el valor de "cost_per" o "roas" según disponibilidad
  function findCostPerOrRoas(fields: any) {
    const costPerField = fields.find((field: any) => field.name === "cost_per");
    const roasField = fields.find((field: any) => field.name === "roas");

    if (costPerField) {
      performanceFormat = false;
      return costPerField.value;
    } else if (roasField) {
      performanceFormat = true;
      return roasField.value;
    }
    return null;
  }

  const costPerOrRoasValue = findCostPerOrRoas(table[0]?.fields);
  if (costPerOrRoasValue) {
    columnNames.splice(3, 1, "performance");
  }

  // console.log(columnNames);

  // Función para encontrar el valor de un campo por su nombre
  function findFieldValue(fields: any, fieldName: string) {
    const field = fields.find((field: any) => field.name === fieldName);
    return field?.value;
  }

  // Función para formatear números con separadores de miles
  function formatNumberWithCommas(number: string | undefined) {
    if (number) {
      return number?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    } else {
      return "0";
    }
  }

  // console.log(investmentPercentage);

  return (
    <AccordionItem>
      <AccordionHeader targetId={id}>
        <Container className="mx-0 my-2 DashboardCards__Container">
          <Row>
            <Col md={3} className="pb-0 pe-0 fs-5 fw-bold">
              {name}
              <div className="py-0 text-muted fs-6 mt-2">ID: {id}</div>
            </Col>

            <Col md={2} className="ps-0">
              <div className="fw-bold fs-5">
                <div className="pb-0 DashboardCards__HeadInvestment mb-2">
                  ${formatNumberWithCommas(investment.toString())}
                </div>
                <div>
                  <PercentageArrow
                    key={`Percentage${investmentPercentage}`}
                    percentage={investmentPercentage}
                    comparission={investmentComparission}
                    prefix={"$"}
                  />
                </div>
              </div>
            </Col>

            <Col md={3}>
              <Row className="fw-bold fs-5">
                <Col md={6} className="pb-0">
                  {formatNumberWithCommas(sessions.toString())}
                  <div className="mt-2">
                    <PercentageArrow
                      key={`Percentage${sessionsPercentage}`}
                      percentage={sessionsPercentage}
                      comparission={sessionsComparission}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  ${formatNumberWithCommas(costSession.toString())}
                  <div className="mt-2">
                    <CostPercentage
                      key={`Percentage${costSessionPercentage}`}
                      percentage={costSessionPercentage}
                      comparission={costSessionComparission}
                      prefix={"$"}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={3} className="ms-4">
              <Row className="fw-bold fs-5">
                <Col md={6} className="pb-0">
                  {formatNumberWithCommas(events.toString())}
                  <div className="mt-2">
                    <PercentageArrow
                      key={`Percentage${eventsPercentage}`}
                      percentage={eventsPercentage}
                      comparission={eventsComparission}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-baseline">
                    {!performanceFormat && "$"}
                    {formatNumberWithCommas(costEvent?.toString())}
                    {!performanceFormat ? (
                      <div className="DashboardCards__HeadPerformance fw-normal text-muted">
                        /Evento
                      </div>
                    ) : (
                      <div className="DashboardCards__HeadPerformance fw-normal text-muted ms-1">
                        (ROAS)
                      </div>
                    )}
                  </div>
                  <div className="mt-2">
                    {performanceFormat ? (
                      <PercentageArrow
                        key={`Percentage${costEventPercentage}`}
                        percentage={costEventPercentage}
                        comparission={costEventComparission}
                      />
                    ) : (
                      <CostPercentage
                        key={`Percentage${costEventPercentage}`}
                        percentage={costEventPercentage}
                        comparission={costEventComparission}
                        prefix={"$"}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </AccordionHeader>
      <AccordionBody accordionId={id}>
        <Table bordered size="sm">
          <thead>
            <tr className="text-center">
              <th></th>
              <th>Sesiones</th>
              <th>$/Sesión</th>
              <th>Eventos</th>
              <th>
                {table?.some(({ fields }) =>
                  fields.some((field: any) => field.name === "roas")
                )
                  ? "ROAS"
                  : "$/Evento"}
              </th>
              <th>Inv. Meta</th>
              <th>Inv. Search</th>
              <th>Inv. Display</th>
            </tr>
          </thead>
          <tbody>
            {table?.map(({ name, fields }, rowIndex) => (
              <tr key={rowIndex}>
                <th scope="row">
                  {format(new Date(`${name}T12:00:00Z`), "dd MMM", {
                    locale: es,
                  })}
                </th>
                {columnNames.map((columnName, columnIndex) => (
                  <td key={columnIndex}>
                    {columnName === "performance"
                      ? formatNumberWithCommas(findCostPerOrRoas(fields))
                      : formatNumberWithCommas(
                          findFieldValue(fields, columnName)
                        )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </AccordionBody>
    </AccordionItem>
  );
}
