import { Col, Container, Row } from "reactstrap";
import { format, utcToZonedTime } from 'date-fns-tz';

import { user } from "../../Views/Campaigns";

import "./index.scss";

interface UserCardProps {
  user: user;
}

export default function UserCard({ user }: UserCardProps) {

  const formatDate = (dateReceived: string) => {
   const milliseconds = parseInt(dateReceived);
   const date = new Date(milliseconds);
   const zonedDate = utcToZonedTime(date, 'Etc/GMT');
   const formattedDate = format(zonedDate, "dd-MM-yyyy");
   return formattedDate;
 };

  return (
    <div className="UserCard">
      <Container className="mx-0 UserCard__Item">
        <Row>
          <Col md={1} className="pb-0 ps-3 fs-6 fw-bold">
            {user.avatar && (
              <img
                src={user.avatar}
                alt="Avatar"
                referrerPolicy="no-referrer"
                className="UserCard__ProfilePic"
              />
            )}
          </Col>

          <Col md={2} className="ps-1 d-flex align-items-center">
            <div className="fs-6">
              <div className="pb-0">{user.firstName && user.firstName}</div>
            </div>
          </Col>

          <Col md={2} className="ps-2 d-flex align-items-center">
            <div className="fs-6">
              <div className="pb-0">{user.lastName && user.lastName}</div>
            </div>
          </Col>

          <Col md={3} className="ps-2 d-flex align-items-center">
            <div className="fs-6">
              <div className="pb-0">{user.username && user.username}</div>
            </div>
          </Col>

          <Col md={2} className="ps-3 d-flex align-items-center">
            <div className="fs-6">
              <div className="pb-0">{user.createdAt && formatDate(user.createdAt)}</div>
            </div>
          </Col>

          <Col md={2} className="ps-3 d-flex align-items-center">
            <div className="fs-6">
              <div className="pb-0">{user.updatedAt && formatDate(user.updatedAt)}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
