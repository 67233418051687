import { Col, Row } from "reactstrap";

import './index.scss'

export default function PlatformsCardsHeader() {
  return (
    <div className="PlatformsCardsHeader">
      <Row className="py-2 mt-5 fs-6 fw-normal sticky-top bg-white PlatformsCardsHeader__Item fw-bold">
        <Col md={{ size: 4, offset: 3 }} className="">
          Descripción
        </Col>
        <Col md={4} className="">
          Configuración
        </Col>
      </Row>
    </div>
  );
}
