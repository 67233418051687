import { Button, Col, Container, Row } from "reactstrap";
import { MdClear, MdMode } from "react-icons/md";

import "./index.scss";
import { platform } from "../Platforms";

interface PlatformCardProps {
  platform: platform;
  setFormShow: (value: React.SetStateAction<boolean>) => void;
  setDataToEdit: (platform: platform) => void;
  deleteData: (id: number | undefined, name: string) => void;
}

export default function PlatformCard({
  platform,
  setFormShow,
  setDataToEdit,
  deleteData,
}: PlatformCardProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const formattedPlatform = {
      ...platform,
    };

    setDataToEdit(formattedPlatform);
    setFormShow((current) => !current);
    scrollToTop();
  };

  const handleDelete = () => {
    // Llama a la función deleteData del componente padre (Clients)
    deleteData(platform.id, platform.name);
  };

  return (
    <div className="PlatformCard">
      <Container className="mx-0 PlatformCard__Item">
        <Row>
          <Col md={3} className="pb-0 ps-3 fs-6 fw-bold">
            {platform.name && platform.name}
          </Col>

          <Col md={4} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">{platform.description && platform.description}</div>
            </div>
          </Col>

          <Col md={4} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">{platform.setting && platform.setting}</div>
            </div>
          </Col>

          <Col md={1} className="ps-2">
            <div className="fw-bold">
              <div className="pb-0 mb-0 d-flex justify-content-end">
                <Button
                  outline
                  color="secondary"
                  className="border-0 PlatformCard__Button py-0 pe-0"
                  onClick={handleClick}
                >
                  <MdMode />
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="border-0 PlatformCard__Button py-0 pe-0"
                  onClick={handleDelete}
                >
                  <MdClear />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
