import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { utcToZonedTime, format } from "date-fns-tz";

import { formatUri } from "../../App";
import { showCustomNotification } from "../../Utils/notifications";
import CustomAlert from "../CustomAlert";
import { GET_PERFORMANCE_DATE } from "./connection";

import "./index.scss";

export interface performanceDate {
  campaignId?: number;
  oldestPerformanceDate?: string;
}

type Props = {
  campaign: number | undefined;
};

export default function CampaignSynchronizer({ campaign }: Props) {
  const { token } = useSelector((state: any) => state);

  const { data, loading } = useQuery(GET_PERFORMANCE_DATE, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  console.log(loading);

  const [startDate, setStartDate] = useState("");
  const [performanceDates, setPerformanceDates] = useState<performanceDate[]>(
    []
  );

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  useEffect(() => {
    if (data?.GetPerformanceDate?.state === "GET_PERFORMANCE_DATE") {
      setPerformanceDates(data.GetPerformanceDate.performanceDates);
    }
  }, [data]);

  // Buscar el oldestPerformanceDate para el campaign actual
  const currentCampaignPerformanceDate = performanceDates.find(
    (date) => date.campaignId === campaign
  );

  const handleSyncClick = async () => {
    try {
      const confirmed = window.confirm(
        "¿Está seguro de que desea iniciar la sincronización de datos?"
      );

      if (!confirmed) {
        return; // Si el usuario cancela, no hacemos nada
      }

      const apiUrl = `${formatUri("http")}/performance-synchronizer`;
      const campaignId = campaign; // Reemplaza con el ID de la campaña que deseas sincronizar

      // Configuración del cuerpo de la solicitud
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fromDate: startDate,
          campaignId: campaignId,
        }),
      });

      // Verifica si la solicitud fue exitosa (código de respuesta 2xx)
      if (response.ok) {
        showCustomNotification(
          "Su solicitud de sincronización de datos ha sido recibida y está en proceso. Pronto verá los datos actualizados.",
          "success",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      } else {
        showCustomNotification(
          "Error: No fue posible sincronizar los datos",
          "danger",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      }
    } catch (error) {
      console.error("Error al sincronizar", error);
      showCustomNotification(
        "Error: No fue posible sincronizar los datos",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
    }
  };

  return (
    <Row className="CampaignSynchronizer align-items-center">
      {loading && (
        <Col md={12} className="d-flex justify-content-center mb-3">
          {/* Muestra el spinner mientras loading es true */}
          <Spinner color="dark" />
        </Col>
      )}
      {!loading && (
        <>
          <CustomAlert
            isOpen={showCustomAlert}
            color={customAlertColor}
            message={customAlertMessage}
            toggle={() => setShowCustomAlert(false)}
          />
          <Col md={3}>
            <h6>Sincronizar datos históricos</h6>
          </Col>
          <Col md={3}>
            <FormGroup className="CampaignSynchronizer__Date">
              <Label for="exampleDate">Fecha de inicio</Label>
              <Input
                id="exampleDate"
                name="date"
                placeholder="date placeholder"
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <Button color="danger" className="me-3" onClick={handleSyncClick}>
              Sincronizar
            </Button>
          </Col>
          {currentCampaignPerformanceDate && (
            <Col md={4}>
              <div>
                <strong>Primera sincronización:</strong>{" "}
                {currentCampaignPerformanceDate.oldestPerformanceDate
                  ? format(
                      utcToZonedTime(
                        new Date(
                          currentCampaignPerformanceDate.oldestPerformanceDate
                        ),
                        "UTC"
                      ),
                      "dd/MM/yyyy",
                      { timeZone: "America/Santiago" }
                    )
                  : "No disponible"}
              </div>
            </Col>
          )}
        </>
      )}
    </Row>
  );
}
