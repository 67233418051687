import { Button, Col, Container, Row } from "reactstrap";
import { MdClear, MdMode } from "react-icons/md";

import "./index.scss";
import { campaignPlatform } from "../CampaignForm";

interface PlatformCardProps {
  campaignPlatform: campaignPlatform;
  setcampaignPlatformformShow: (value: React.SetStateAction<boolean>) => void;
  setCampaignPlatformToEdit: (campaignPlatform: campaignPlatform) => void;
  deleteCampaignPlatformConfirmation: (id: number | undefined, apiKey: string) => void;
}

export default function CampaignPlatformCard({
  campaignPlatform,
  setcampaignPlatformformShow,
  setCampaignPlatformToEdit,
  deleteCampaignPlatformConfirmation,
}: PlatformCardProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const formattedCampaignPlatform = {
      ...campaignPlatform,
    };

    setCampaignPlatformToEdit(formattedCampaignPlatform);
    setcampaignPlatformformShow((current) => !current);
    scrollToTop();
  };

  const handleDelete = () => {
    // Llama a la función deleteData del componente padre (Clients)
    deleteCampaignPlatformConfirmation(campaignPlatform.id, campaignPlatform.apiKey);
  };

  return (
    <div className="CampaignPlatformCard">
      <Container className="mx-0 CampaignPlatformCard__Item">
        <Row>
          <Col md={5} className="pb-0 ps-3 fs-6">
            {campaignPlatform.apiKey && campaignPlatform.apiKey}
          </Col>

          <Col md={5} className="pb-0 ps-3 fs-6">
            {campaignPlatform.setting && campaignPlatform.setting}
          </Col>

          <Col md={2} className="ps-2">
            <div className="fw-bold">
              <div className="pb-0 mb-0 d-flex justify-content-end">
                <Button
                  outline
                  color="secondary"
                  className="border-0 CampaignPlatformCard__Button py-0 pe-0"
                  onClick={handleClick}
                >
                  <MdMode />
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="border-0 CampaignPlatformCard__Button py-0 pe-0"
                  onClick={handleDelete}
                >
                  <MdClear />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
