import { Col, Row } from "reactstrap";

import './index.scss'

export default function UsersCardsHeader() {
  return (
    <div className="UsersCardsHeader">
      <Row className="py-2 mt-5 fs-6 fw-normal sticky-top bg-white UsersCardsHeader__Item fw-bold">
        <Col md={{ size: 2, offset: 1 }} className="">
          Nombre
        </Col>
        <Col md={2} className="">
          Apellido
        </Col>
        <Col md={3} className="">
          Correo
        </Col>
        <Col md={2} className="">
          Creación
        </Col>
        <Col md={2} className="">
          Actualización
        </Col>
      </Row>
    </div>
  );
}
