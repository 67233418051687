
import { Alert } from "reactstrap";

type Props = {
  isOpen: boolean;
  color: string;
  message: string;
  toggle: () => void;
}

export default function CustomAlert({
  isOpen,
  color,
  message,
  toggle,
}: Props) {

  return (
    <Alert color={color} isOpen={isOpen} toggle={toggle}>
      {message}
    </Alert>
  )
}
