import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import "./index.scss";
import { ClientCard, ClientForm, CustomAlert } from "../../Components";
import { MdAdd } from "react-icons/md";
import { GET_ALL_CLIENTS } from "./connection";
import { formatUri } from "../../App";
import { showCustomNotification } from "../../Utils/notifications";

export interface client {
  id?: number;
  name: string;
  description?: string;
  rut?: string;
  logoUrl: string;
  color?: string;
  abbr?: string;
  active?: boolean;
}

export default function Clients() {
  const { token } = useSelector((state: any) => state);

  const e_GetClients = useQuery(GET_ALL_CLIENTS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  // Menú Tab de clientes activos o inactivos
  const [tabSelected, setTabSelected] = useState(1);

  // Formulario de clientes
  const [formShow, setFormShow] = useState(false);

  // Data de clientes
  const [clientData, setClientData] = useState<client[]>([]);
  const [dataToEdit, setDataToEdit] = useState<client>({} as client);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  useEffect(() => {
    if (e_GetClients.data?.GetAllClients?.state === "GET_ALL_CLIENTS") {
      setClientData(e_GetClients.data.GetAllClients.clients);
    }
  }, [e_GetClients.data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDataToEdit({} as client);
    setFormShow((current) => !current);
  };

  const handleUpdateClient = (updatedClient: client) => {
    console.log(updatedClient);
    // Buscar el índice del cliente en la lista actual
    const updatedIndex = clientData.findIndex(
      (client) => client.id === updatedClient.id
    );

    if (updatedIndex !== -1) {
      // Si el cliente existe en la lista, reemplazarlo con los datos actualizados
      const updatedClients = [...clientData];
      updatedClients[updatedIndex] = updatedClient;
      setClientData(updatedClients);

      showCustomNotification(
        "Cliente actualizado exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } else {
      // Si el cliente no existe en la lista, agregarlo como un nuevo cliente
      setClientData((prevClientData) => [...prevClientData, updatedClient]);
      showCustomNotification(
        "Cliente creado exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  // Para actualizar la lista de clientes después de eliminar
  const updateClientList = (updatedClients: client[]) => {
    setClientData(updatedClients);
  };

  const deleteData = (id: number | undefined, name: string) => {
    let isDelete = window.confirm(`¿Estás seguro de eliminar '${name}'?`);

    if (isDelete) {
      // Llama a la función deleteClient para eliminar el cliente
      deleteClient(id);
    } else {
      return;
    }
  };

  //Eliminación de clientes
  const deleteClient = async (clientId: number | undefined) => {
    try {
      if (!clientId) {
        throw new Error("ID del cliente no válido");
      }

      const apiUrl = `${formatUri("http")}/clients-api`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: clientId }),
      });

      if (!response.ok) {
        throw new Error("Error al eliminar el cliente");
      }
      const updatedClients = clientData.filter(
        (client) => client.id !== clientId
      );
      updateClientList(updatedClients);
      scrollToTop();
      showCustomNotification(
        "Cliente eliminado exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } catch (error) {
      console.error("Error al eliminar el cliente:", error);
      scrollToTop();
      showCustomNotification(
        "Error al eliminar el cliente",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  return (
    <Container fluid className="Clients">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Row>
        <Col xs={{ size: 8, offset: 2 }} className="mb-4">
          <h4>
            <small
              style={{
                // small-caps
                fontVariant: "small-caps",
                fontSize: "1rem",
                fontWeight: "normal",
                userSelect: "none",
              }}
            >
              herramienta de reportes
            </small>
            <br />
            Clientes
          </h4>
          <Button
            className="Clients__New FormFilter__Buttons__Submit mt-4 me-2"
            color="dark"
            outline
            onClick={handleClick}
          >
            Nuevo
            <MdAdd />
          </Button>
          {formShow && (
            <ClientForm
              dataToEdit={dataToEdit}
              setDataToEdit={setDataToEdit}
              setFormShow={setFormShow}
              handleUpdateClient={handleUpdateClient}
              clients={clientData}
            />
          )}
        </Col>
      </Row>
      <Row className="mt-2 mb-4">
        <Col
          xs={{
            size: 8,
            offset: 2,
          }}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                active={tabSelected === 1}
                onClick={() => setTabSelected(1)}
              >
                Activos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tabSelected === 2}
                onClick={() => setTabSelected(2)}
              >
                Inactivos
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabSelected.toString()}>
            <TabPane tabId="1">
              <Row className="row-cols-lg-4 row-cols-xl-5">
                {clientData &&
                  clientData.map((client) => {
                    if (client.active) {
                      return (
                        <Col className="mt-5">
                          <ClientCard
                            key={client.id}
                            client={client}
                            setDataToEdit={setDataToEdit}
                            deleteData={deleteData}
                            setFormShow={setFormShow}
                          />
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="row-cols-lg-4 row-cols-xl-5">
                {clientData &&
                  clientData.map((client) => {
                    if (client.active === false) {
                      return (
                        <Col className="mt-5">
                          <ClientCard
                            key={client.id}
                            client={client}
                            setDataToEdit={setDataToEdit}
                            deleteData={deleteData}
                            setFormShow={setFormShow}
                          />
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
