import { Modal, ModalBody, Spinner, UncontrolledAccordion } from "reactstrap";

import "./index.scss";
import CardItem from "./CardItem";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_DASHBOARD_CLIENTS } from "../../Views/Home/connection";
import { useSelector } from "react-redux";

interface DashboardCardsProps {
  presetPercentage: number;
  showOnlyInvestment?: boolean;
}

export default function DashboardCards({
  presetPercentage,
  showOnlyInvestment = false,
}: DashboardCardsProps) {
  const { token } = useSelector((state: any) => state);

  const [clients, setClients] = useState([]);
  const { data, loading } = useQuery(GET_DASHBOARD_CLIENTS, {
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
    variables: {
      DashboardListClientsInput: {
        presetPercentage,
      },
    },
  });

  useEffect(() => {
    if (data?.DashboardListClients?.cards) {
      setClients(data?.DashboardListClients?.cards);
    }
  }, [data]);

  // console.log(clients)

  const perClient = clients.reduce((acc: any, client: any) => {
    if (acc[client.name]) {
      acc[client.name].push(client);
    } else {
      acc[client.name] = [client];
    }
    return acc;
  }, []);

  // Ordenar el objeto perClient por nombre de cliente
  const sortedClients = Object.entries(perClient).sort((a, b) => {
    const nameA = a[0].toLowerCase();
    const nameB = b[0].toLowerCase();
    return nameA.localeCompare(nameB);
  });

  return (
    <>
      <Modal isOpen={loading}>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            color="danger"
            type="grow"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />
          <br />
          Obteniendo información...
        </ModalBody>
      </Modal>
      {sortedClients.map((_clients: any, index: any) => {
        const [client, cards] = _clients;
        const clientColor = cards[0].color;

        // console.log(cards)
        return (
          <div
            className="bg-white DashboardCards__Content mb-3"
            style={{ borderColor: clientColor }}
          >
            {/* @ts-ignore */}
            <UncontrolledAccordion
              defaultOpen={[]}
              stayOpen
              key={`Uncontrolled${client}`}
            >
              {cards.map((client: any, index: number) => (
                <CardItem
                  client={client}
                  key={index}
                  showOnlyInvestment={showOnlyInvestment}
                />
              ))}
            </UncontrolledAccordion>
          </div>
        );
      })}
    </>
  );
}
