import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  FormText,
  FormFeedback,
} from "reactstrap";
import { client } from "../../Views/Clients";

import "./index.scss";
import { formatUri } from "../../App";
import CustomAlert from "../CustomAlert";
import { useSelector } from "react-redux";
import { showCustomNotification } from "../../Utils/notifications";

const initialForm = {
  name: "",
  description: "",
  rut: "",
  logoUrl: "",
  color: "#ffffff",
  abbr: "",
  active: false,
} as client;

type Props = {
  dataToEdit: client;
  setDataToEdit: (client: client) => void;
  setFormShow: (value: React.SetStateAction<boolean>) => void;
  handleUpdateClient: (client: client) => void;
  clients: client[];
};

const validateName = (name: string) => {
  if (!name) {
    return "El nombre es obligatorio";
  }
  return "";
};

export default function ClientForm({
  dataToEdit,
  setDataToEdit,
  setFormShow,
  handleUpdateClient,
  clients,
}: Props) {
  const [form, setForm] = useState(initialForm);

  // Validaciones
  const [formErrors, setFormErrors] = useState<Partial<client>>({});
  const [isNameValid, setIsNameValid] = useState(true);
  const [isRutValid, setIsRutValid] = useState(true);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  const { token } = useSelector((state: any) => state);

  useEffect(() => {
    if (Object.keys(dataToEdit).length !== 0) {
      setForm(dataToEdit);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const duplicateClientName = (name: string) => {
    const duplicateClient = clients.find(
      (client) =>
        client.id !== form.id &&
        client.name.toLowerCase() === name.toLowerCase()
    );

    if (duplicateClient) {
      setIsNameValid(false);
      return false;
    } else {
      setIsNameValid(true);
      return true;
    }
  };

  // Validar el formato del RUT
  const validateRutFormat = (value: string | undefined) => {
    if (!value) {
      return true; // Acepta campo vacío
    }
    const isValid = /^[0-9]+[-‐]{1}[0-9kK]{1}$/.test(value);
    setIsRutValid(isValid);
    return isValid;
  };

  // Validar el dígito verificador del RUT
  const validateRutDigit = (value: string | undefined) => {
    if (!value) {
      return true; // Acepta campo vacío
    }
    value = value.split(".").join("");
    value = value.split("-")[0];

    let check = 0;
    let mul = 2;
    for (let i = value.length - 1; i >= 0; i--) {
      check = check + parseInt(value.charAt(i)) * mul;
      mul = mul === 7 ? 2 : mul + 1;
    }

    let digit: string | number = 11 - (check % 11);
    digit = digit === 11 ? 0 : digit;
    digit = digit === 10 ? "k" : digit.toString();

    const isValid = value.slice(-1).toLowerCase() === digit;
    setIsRutValid(isValid);
    return isValid;
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value =
      event.currentTarget.type === "checkbox"
        ? event.currentTarget.checked
        : event.currentTarget.value;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleConfirm = (message: string) => {
    return window.confirm(message);
  };

  const handleConfirmClose = () => {
    const hasChanges = Object.keys(form).some((key) => {
      return form[key as keyof client] !== initialForm[key as keyof client];
    });

    if (hasChanges) {
      const confirmationMessage =
        "¿Estás seguro de que deseas cerrar sin guardar los cambios?";
      if (handleConfirm(confirmationMessage)) {
        handleReset();
      }
    } else {
      handleReset();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    // Validaciones antes de enviar los datos al servidor
    const nameError = validateName(form.name);

    if (nameError) {
      // Si hay errores de validación, muestra las alertas
      showCustomNotification(
        "Por favor, completa todos los campos obligatorios.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      setFormErrors({
        name: nameError,
      });
      return;
    }

    if (!isRutValid) {
      showCustomNotification(
        "Por favor, ingresa un RUT válido o deja en blanco.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      return;
    }

    if (!isNameValid) {
      showCustomNotification(
        "Por favor, ingresa un nombre válido.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      return;
    }

    const updatedForm = {
      ...form,
      logoUrl: form.logoUrl || "https://placehold.co/400?text=No+image",
    };

    try {
      const apiUrl = `${formatUri("http")}/clients-api`;
      const method = dataToEdit.id ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedForm),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (dataToEdit.id) {
          handleUpdateClient(updatedForm);
          setFormShow(false);
        } else {
          handleUpdateClient(data);
          setFormShow(false);
        }
      } else {
        showCustomNotification(
          "Error: No fue posible guardar los cambios",
          "danger",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      }
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      showCustomNotification(
        "Error: No fue posible guardar los cambios",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
    }
  };

  const handleReset = () => {
    setForm(initialForm);
    setDataToEdit(initialForm);
    setFormShow((current) => !current);
  };

  return (
    <Container fluid className="ClientForm my-4">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Card className="ClientForm__Card">
        <CardBody>
          <CardTitle tag="h5" className="mb-4">
            {Object.keys(dataToEdit).length !== 0
              ? "Editar cliente"
              : "Nuevo cliente"}
          </CardTitle>
          <CardText>
            <Form
              autoComplete="off"
              className="Client_Form"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Nombre*</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={form.name}
                      invalid={!isNameValid}
                      onFocus={() => {
                        setShowCustomAlert(false);
                        formErrors.name = undefined;
                      }}
                      onBlur={(e) => {
                        if (!duplicateClientName(e.target.value)) {
                          setIsNameValid(false);
                        }
                      }}
                    />
                    <FormFeedback>
                      Ya existe un cliente con este nombre
                    </FormFeedback>
                    {formErrors.name && (
                      <span className="text-danger">{formErrors.name}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>Abreviación</Label>
                    <Input
                      name="abbr"
                      type="text"
                      onChange={handleChange}
                      value={form.abbr}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>RUT</Label>
                    <Input
                      name="rut"
                      type="text"
                      onChange={handleChange}
                      value={form.rut}
                      invalid={!isRutValid}
                      onFocus={() => {
                        setIsRutValid(true);
                      }}
                      onBlur={(e) => {
                        if (
                          !validateRutFormat(e.target.value) &&
                          !validateRutDigit(e.target.value)
                        ) {
                          setIsRutValid(false);
                        }
                      }}
                    />
                    <FormFeedback>RUT invalido</FormFeedback>
                    <FormText>Ingresar sin puntos y con guion</FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup className="mt-2">
                    <Label>Url del logotipo</Label>
                    <Col sm={16}>
                      <Input
                        name="logoUrl"
                        type="url"
                        onChange={handleChange}
                        value={form.logoUrl}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup className="mt-2">
                    <Label>Color</Label>
                    <Input
                      name="color"
                      placeholder="color placeholder"
                      type="color"
                      className="ClientForm__InputColor"
                      onChange={handleChange}
                      value={form.color}
                    />
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-2 d-flex justify-content-center">
                  <FormGroup switch>
                    <Label check className="">
                      Activo
                    </Label>
                    <Input
                      name="active"
                      type="switch"
                      checked={form.active}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup className="mt-2">
                    <Label>Descripción</Label>
                    <Input
                      name="description"
                      type="textarea"
                      onChange={handleChange}
                      value={form.description}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Button color="danger" className="me-3" type="submit">
                  Guardar
                </Button>
                <Link
                  className="btn btn-danger"
                  role="button"
                  to="/clients"
                  onClick={handleConfirmClose}
                >
                  Cerrar
                </Link>
              </div>
            </Form>
          </CardText>
        </CardBody>
      </Card>
    </Container>
  );
}
