import { Col, Row } from "reactstrap";

import './index.scss'

export default function CampaignsCardsHeader() {
  return (
    <div className="CampaignsCardsHeader">
      <Row className="py-2 mt-5 fs-6 fw-normal sticky-top bg-white CampaignsCardsHeader__Item fw-bold">
        <Col md={{ size: 2, offset: 2 }} className="">
          Presupuesto
        </Col>
        <Col md={2} className="">
          Tipo
        </Col>
        <Col md={2} className="">
          Inicio
        </Col>
        <Col md={2} className="">
          Término
        </Col>
        <Col md={1} className="p-0">
          Optimizador(a)
        </Col>
      </Row>
    </div>
  );
}
