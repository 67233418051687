import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { MdAdd } from "react-icons/md";
import { Button } from "reactstrap";

import PlatformForm from "../PlatformForm";
import PlatformsCardsHeader from "../PlatformsCardsHeader";
import PlatformCard from "../PlatformCard";
import { formatUri } from "../../App";
import { GET_ALL_PLATFORMS } from "../../Views/Configuration/connection";

import "./index.scss";
import { showCustomNotification } from "../../Utils/notifications";

export interface platform {
  id?: number;
  name: string;
  description?: string;
  setting: string;
}

interface PlatformsProps {
  setShowCustomAlert: (value: React.SetStateAction<boolean>) => void;
  setCustomAlertMessage: (value: string) => void;
  setCustomAlertColor: (value: string) => void;
}

export default function Platforms({
  setShowCustomAlert,
  setCustomAlertMessage,
  setCustomAlertColor,
}: PlatformsProps) {
  const { token } = useSelector((state: any) => state);

  const e_GetPlatforms = useQuery(GET_ALL_PLATFORMS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };
  // Formularios
  const [formShow, setFormShow] = useState(false);
  const [platforms, setPlatforms] = useState<platform[]>([]);
  const [dataToEdit, setDataToEdit] = useState<platform>({} as platform);

  useEffect(() => {
    if (e_GetPlatforms.data?.GetAllPlatforms?.state === "GET_ALL_PLATFORMS") {
      setPlatforms(e_GetPlatforms.data.GetAllPlatforms.platforms);
    }
  }, [e_GetPlatforms.data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDataToEdit({} as platform);
    setFormShow((current) => !current);
  };

  const handleUpdatePlatform = (updatedPlatform: platform) => {
    console.log(updatedPlatform);
    // Buscar el índice del cliente en la lista actual
    const updatedIndex =
      platforms &&
      platforms.findIndex((platform) => platform.id === updatedPlatform.id);

    if (updatedIndex !== -1) {
      // Si el cliente existe en la lista, reemplazarlo con los datos actualizados
      const updatedPlatforms = [...platforms];
      updatedPlatforms[updatedIndex] = updatedPlatform;
      setPlatforms(updatedPlatforms);

      showCustomNotification(
        "Plataforma actualizada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } else {
      // Si el cliente no existe en la lista, agregarlo como un nuevo cliente
      setPlatforms((prevPlatforms) => [...prevPlatforms, updatedPlatform]);
      showCustomNotification(
        "Plataforma creada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  // Para actualizar la lista después de eliminar
  const updatePlatformList = (updatedPlatforms: platform[]) => {
    setPlatforms(updatedPlatforms);
  };

  const deleteData = (id: number | undefined, name: string) => {
    let isDelete = window.confirm(`¿Estás seguro de eliminar '${name}'?`);

    if (isDelete) {
      deletePlatform(id);
    } else {
      return;
    }
  };

  //Eliminación de plataformas
  const deletePlatform = async (platformId: number | undefined) => {
    try {
      if (!platformId) {
        throw new Error("ID de la plataforma no válido");
      }

      const apiUrl = `${formatUri("http")}/platforms-api`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: platformId }),
      });

      if (!response.ok) {
        throw new Error("Error al eliminar la plataforma");
      }
      const updatedPlatforms = platforms.filter(
        (platform) => platform.id !== platformId
      );
      updatePlatformList(updatedPlatforms);
      scrollToTop();
      showCustomNotification(
        "Plataforma eliminada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } catch (error) {
      console.error("Error al eliminar la plataforma:", error);
      scrollToTop();
      showCustomNotification(
        "Error al eliminar la plataforma",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-row-reverse">
        <Button
          className="Platforms__New FormFilter__Buttons__Submit mt-4 me-2 "
          color="dark"
          outline
          onClick={handleClick}
        >
          Agregar plataforma
          <MdAdd />
        </Button>
      </div>

      {formShow && (
        <PlatformForm
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
          setFormShow={setFormShow}
          handleUpdatePlatform={handleUpdatePlatform}
          platforms={platforms}
        />
      )}
      <PlatformsCardsHeader />
      {platforms &&
        platforms.map((platform) => {
          return (
            <PlatformCard
              key={platform.id}
              platform={platform}
              setDataToEdit={setDataToEdit}
              setFormShow={setFormShow}
              deleteData={deleteData}
            />
          );
        })}
    </>
  );
}
