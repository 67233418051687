import { FormGroup, Row, Col, Label, Input } from 'reactstrap'

import { SelectFilterProps } from './types'
import './index.scss'

export default function SelectFilter({
  id,
  label,
  placeholder,
  options,
  selectedFormat,
  setSelectedFormat,
  disabled = false
}: SelectFilterProps) {

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (setSelectedFormat) {
      setSelectedFormat(event.currentTarget.value);
    }
  };

  return (
    <FormGroup className='bg-white'>
      <Row>
        <Col md={2}>
          <div className='SelectFilter__Label'>
            <Label for={id}>
              {label}
            </Label>
          </div>
        </Col>
        <Col md={10} className='pe-3'>
          <Input /*id={id}*/ name={id} value={selectedFormat} type='select' className='SelectFilter__Select' onChange={handleChange} disabled={disabled}>
            {placeholder && (
              <option selected disabled value=''>
                {placeholder}
              </option>
            )}
            {options.map(({ name, id }) => (
              <option value={id}>{name}</option>
            ))}
          </Input>
        </Col>
      </Row>
    </FormGroup>
  )
}
