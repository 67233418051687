import { gql } from "@apollo/client";

export const USER_ME = gql`
query UserMe {
	UserMe {
		state
		refreshToken
		lastUpdate
		user {
			id
			firstName
			lastName
			avatar
			locale
			typeId
			username
		}
	}
}
`