import { Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";

import { IoPersonCircleSharp } from "react-icons/io5";

import {
  MdBusinessCenter,
  MdFilterAlt,
  MdAdsClick,
  MdFilterNone,
  MdUpdate,
  MdCheck,
  MdSettings,
} from "react-icons/md";

import { Link } from "react-router-dom";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { USER_ME } from "./connection";
import { useEffect, useState } from "react";
import { getUserData, logout } from "../../Store/Actions";
import Lottie from "react-lottie";

interface AppFrameProps {
  children: React.ReactNode;
}

export default function AppFrame({ children }: AppFrameProps) {
  const { token, userInfo, lastUpdate } = useSelector((state: any) => state);
  const { fullName, avatar } = userInfo;
  const dispatch = useDispatch();

  const { data, loading } = useQuery(USER_ME, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  // console.log(decodeJwt(token));

  useEffect(() => {
    if (data?.UserMe?.user) {
      dispatch(getUserData(data?.UserMe));
    }
  }, [data, dispatch]);

  const [isPlaying, setIsPlaying] = useState(true);

  return (
    <div className="AppFrame__Wrapper">
      <div className="d-flex flex-column flex-shrink-0 AppFrame__Left">
        <ul className="flex-column mb-auto text-center p-1">
          <Nav pills flush="true">
            <ul>
              <NavItem>
                <Link
                  component={NavLink}
                  to="/"
                  className="fs-2 icon py-3 link-dark"
                  onClick={(e) => {
                    // e.preventDefault()
                    setIsPlaying(!isPlaying);
                  }}
                >
                  {/* <IconContingente width={'1em'} height='1em' /> */}
                  <Lottie
                    options={{
                      animationData: require("../../Assets/LoaderContingente.json"),
                      loop: false,
                    }}
                    eventListeners={[
                      {
                        eventName: "complete",
                        callback: () => setIsPlaying(false),
                      },
                      {
                        eventName: "loopComplete",
                        callback: () => setIsPlaying(false),
                      },
                    ]}
                    isStopped={!isPlaying}
                    width={"1em"}
                  />
                </Link>
              </NavItem>
            </ul>
            <ul>
              <UncontrolledTooltip placement="right" target="Clientes">
                Clientes
              </UncontrolledTooltip>
              <NavItem id="Clientes">
                <Link
                  component={NavLink}
                  to="/clients"
                  className="fs-2 icon py-3 link-dark"
                >
                  <MdBusinessCenter />
                </Link>{" "}
                {/* /client/new */}
              </NavItem>
            </ul>
            {/* <ul>
              <UncontrolledTooltip placement="right" target="Campanas">
                Campañas
              </UncontrolledTooltip>
              <NavItem id="Campanas">
                <Link
                  component={NavLink}
                  to="campaigns"
                  className="fs-2 icon py-3 link-dark"
                >
                  <FaCampground />
                </Link>
              </NavItem>
            </ul> */}
            <ul className="AppFrame__Disabled">
              <UncontrolledTooltip placement="right" target="Embudo">
                Embudo
              </UncontrolledTooltip>
              <NavItem id="Embudo">
                <Link
                  component={NavLink}
                  to="#"
                  className="fs-2 icon py-3 link-dark"
                >
                  <MdFilterAlt />
                </Link>
              </NavItem>
            </ul>
            <ul className="AppFrame__Disabled">
              <UncontrolledTooltip placement="right" target="Avisos">
                Avisos
              </UncontrolledTooltip>
              <NavItem id="Avisos">
                <Link
                  component={NavLink}
                  to="#"
                  className="fs-2 icon py-3 link-dark"
                >
                  <MdAdsClick />
                </Link>
              </NavItem>
            </ul>
            <ul className="AppFrame__Disabled">
              <UncontrolledTooltip placement="right" target="Informes">
                Informes
              </UncontrolledTooltip>
              <NavItem id="Informes">
                <Link
                  component={NavLink}
                  to="#"
                  className="fs-2 icon py-3 link-dark"
                >
                  <MdFilterNone />
                </Link>
              </NavItem>
            </ul>
          </Nav>
        </ul>
        <div>

          <UncontrolledTooltip
            placement="right"
            target="Configuration"
          >
            Configuración
          </UncontrolledTooltip>
          <div
            aria-expanded="false"
            id="Configuration"
          >
            <Link
              component={NavLink}
              to="/settings"
              className="fs-4 icon d-flex align-items-center justify-content-center p-3 div-dark text-decoration-none link-dark"
            >
              <MdSettings />
            </Link>
          </div>

          <UncontrolledTooltip
            placement="right"
            target="SyncStatus"
            style={{
              textAlign: "left",
            }}
          >
            <MdCheck /> última vez {lastUpdate}.
          </UncontrolledTooltip>
          <div
            className="fs-4 icon d-flex align-items-center justify-content-center p-3 div-dark text-decoration-none"
            aria-expanded="false"
            id="SyncStatus"
          >
            <MdUpdate />
          </div>

          <UncontrolledTooltip placement="right" target="ProfileUser">
            Cerrar Sesión {fullName ? `de ${fullName}` : ""}
          </UncontrolledTooltip>
          <Link
            to="#"
            onClick={() => {
              dispatch(logout(null));
            }}
            className="fs-4 icon d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none"
            aria-expanded="false"
            id="ProfileUser"
          >
            {!avatar && <IoPersonCircleSharp />}
            {avatar && (
              <img
                src={avatar}
                alt={`Avatar ${fullName ? `de ${fullName}` : ""}`}
                referrerPolicy="no-referrer"
                className="AppFrame__ProfilePic"
              />
            )}
          </Link>
        </div>
      </div>
      {!loading && <div className="AppFrame__Content">{children}</div>}
    </div>
  );
}


