import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  FormFeedback,
} from "reactstrap";
import { client } from "../../Views/Clients";

import { formatUri } from "../../App";
import CustomAlert from "../CustomAlert";
import { campaign } from "../../Views/Campaigns";
import { user } from "../../Views/Campaigns";
import { showCustomNotification } from "../../Utils/notifications";
import CampaignPlatforms from "../CampaignPlatforms";
import CampaignSynchronizer from "../CampaignSynchronizer";
import { GET_CAMPAIGN_PLATFORMS } from "../../Views/Configuration/connection";

import "./index.scss";

export interface campaignPlatform {
  id?: number;
  campaignId: number;
  platformId: number;
  clientId: number;
  apiKey: string;
  setting: string;
}

const initialForm = {
  clientId: 0,
  name: "",
  description: "",
  active: true,
  budget: undefined,
  monthlyBudget: true,
  startAt: new Date().toISOString().split("T")[0],
  endAt: undefined,
  optimizerId: 0,
} as campaign;

type Props = {
  dataToEdit: campaign;
  setDataToEdit: (campaign: campaign) => void;
  setFormShow: (value: React.SetStateAction<boolean>) => void;
  handleUpdateCampaign: (campaign: campaign) => void;
  users: user[];
  selectedClient: client | undefined;
  campaigns: campaign[];
};

const validateName = (name: string) => {
  if (!name) {
    return "El nombre es obligatorio";
  }
  return "";
};

export default function CampaignForm({
  dataToEdit,
  setDataToEdit,
  setFormShow,
  handleUpdateCampaign,
  users,
  selectedClient,
  campaigns,
}: Props) {
  const { token } = useSelector((state: any) => state);

  const e_GetCampaignPlatforms = useQuery(GET_CAMPAIGN_PLATFORMS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const [form, setForm] = useState(initialForm);

  //Almacena al usuario seleccionado
  const [selectedUser, setSelectedUser] = useState<user>();
  const [campaignPlatforms, setCampaignPlatforms] = useState<
    campaignPlatform[]
  >([]);

  // Validaciones
  const [formErrors, setFormErrors] = useState<Partial<client>>({});
  const [isNameValid, setIsNameValid] = useState(true);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  useEffect(() => {
    if (
      e_GetCampaignPlatforms.data?.GetCampaignPlatforms?.state ===
      "GET_CAMPAIGN_PLATFORMS"
    ) {
      setCampaignPlatforms(
        e_GetCampaignPlatforms.data.GetCampaignPlatforms.campaign_platforms
      );
    }
  }, [e_GetCampaignPlatforms.data]);

  useEffect(() => {
    if (Object.keys(dataToEdit).length !== 0) {
      setForm(dataToEdit);
      const optimizer = users.find(
        (user) => user.id === dataToEdit.optimizerId
      );
      setSelectedUser(optimizer);
    } else {
      setForm(initialForm);
      setSelectedUser(undefined);
    }
  }, [dataToEdit, users]);

  // Filtrar las campaignPlatforms asociadas a la campaña seleccionada
  const campaignPlatformsForSelectedCampaign = campaignPlatforms.filter(
    (campaignPlatform) => campaignPlatform.campaignId === dataToEdit.id
  );

  // Obtener la cantidad de campaignPlatforms asociadas a la campaña seleccionada
  const numberOfCampaignPlatforms = campaignPlatformsForSelectedCampaign.length;

  const duplicateCampaignName = (name: string) => {
    const duplicateCampaign = campaigns.find(
      (campaign) =>
        campaign.id !== form.id &&
        campaign.name.toLowerCase() === name.toLowerCase()
    );

    if (duplicateCampaign) {
      setIsNameValid(false);
      return false; // Set isNameValid to false if
    } else {
      setIsNameValid(true);
      return true;
    }
  };

  const userHandleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const userId = Number(event.currentTarget.value);
    const _user = users.find((u: any) => u.id === userId);
    setSelectedUser(_user);

    // Actualizar el optimizerId en el estado del formulario
    setForm({
      ...form,
      optimizerId: userId,
    });
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    let value: any =
      event.currentTarget.type === "checkbox"
        ? event.currentTarget.checked
        : event.currentTarget.value;

    if (
      (name === "startAt" || name === "endAt") &&
      event.currentTarget.value === ""
    ) {
      value = undefined;
    }

    if (name === "budget" && event.currentTarget.value === "") {
      value = 0;
    }

    const clientId = selectedClient?.id || 0;

    setForm({
      ...form,
      [name]: value,
      clientId: clientId,
    });
  };

  const handleConfirm = (message: string) => {
    return window.confirm(message);
  };

  const handleConfirmClose = () => {
    const hasChanges = Object.keys(form).some((key) => {
      return form[key as keyof campaign] !== initialForm[key as keyof campaign];
    });

    if (hasChanges) {
      const confirmationMessage =
        "¿Estás seguro de que deseas cerrar sin guardar los cambios?";
      if (handleConfirm(confirmationMessage)) {
        handleReset();
      }
    } else {
      handleReset();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validaciones antes de enviar los datos al servidor
    const nameError = validateName(form.name);

    if (nameError) {
      // Si hay errores de validación, muestra las alertas
      showCustomNotification(
        "Por favor, completa el campo obligatorio.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      setFormErrors({
        name: nameError,
      });
      return;
    }

    if (!isNameValid) {
      showCustomNotification(
        "Por favor, ingresa un nombre válido.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      return;
    }

    console.log(form);
    try {
      const apiUrl = `${formatUri("http")}/campaigns-api`;
      const method = dataToEdit.id ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (dataToEdit.id) {
          handleUpdateCampaign(form);
          setFormShow(false);
        } else {
          handleUpdateCampaign(data);
          setFormShow(false);
        }
      } else {
        showCustomNotification(
          "Error: No fue posible guardar los cambios",
          "danger",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      }
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      showCustomNotification(
        "Error: No fue posible guardar los cambios",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
    }
  };

  const handleReset = () => {
    setForm(initialForm);
    setDataToEdit(initialForm);
    setFormShow((current) => !current);
  };

  return (
    <Container fluid className="CampaignForm my-4">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Card className="CampaignForm__Card">
        <CardBody>
          <CardTitle tag="h5" className="mb-4">
            {Object.keys(dataToEdit).length !== 0
              ? "Editar campaña"
              : "Agregar campaña"}
          </CardTitle>
          <CardText>
            <Form
              autoComplete="off"
              className="Client_Form"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Nombre*</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={form.name}
                      invalid={!isNameValid}
                      onFocus={() => {
                        setShowCustomAlert(false);
                        formErrors.name = undefined;
                      }}
                      onBlur={(e) => {
                        if (!duplicateCampaignName(e.target.value)) {
                          setIsNameValid(false);
                        }
                      }}
                    />
                    <FormFeedback>
                      Ya existe una campaña con este nombre
                    </FormFeedback>
                    {formErrors.name && (
                      <span className="text-danger">{formErrors.name}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Presupuesto</Label>
                    <Input
                      name="budget"
                      type="number"
                      onChange={handleChange}
                      value={form.budget}
                      className="CampaignForm__BudgetInput"
                    />
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-2 d-flex justify-content-center">
                  <FormGroup switch>
                    <Label check className="">
                      Mensual
                    </Label>
                    <Input
                      name="monthlyBudget"
                      type="switch"
                      checked={form.monthlyBudget}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup className="mt-2">
                    <Label>Fecha de inicio</Label>
                    <Input
                      name="startAt"
                      type="date"
                      onChange={handleChange}
                      value={form.startAt || ""}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mt-2">
                    <Label>Fecha de término</Label>
                    <Input
                      name="endAt"
                      type="date"
                      onChange={handleChange}
                      value={form.endAt || ""}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mt-2">
                    <Label for="exampleSelect">Optimizador(a)</Label>
                    <Input
                      id="user"
                      name="user"
                      type="select"
                      value={selectedUser ? selectedUser.id : ""}
                      onChange={userHandleChange}
                    >
                      <option selected disabled value="">
                        Seleccionar
                      </option>
                      {users.map(({ firstName, lastName, id }) => (
                        <option key={id} value={id}>
                          {firstName} {lastName}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <FormGroup className="mt-2">
                    <Label>Descripción</Label>
                    <Input
                      name="description"
                      type="textarea"
                      onChange={handleChange}
                      value={form.description}
                    />
                  </FormGroup>
                </Col>
                <Col md={2} className="mt-2 d-flex justify-content-center">
                  <FormGroup switch>
                    <Label check className="">
                      Activa
                    </Label>
                    <Input
                      name="active"
                      type="switch"
                      checked={form.active}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {Object.keys(dataToEdit).length !== 0 && (
                <CampaignPlatforms
                  setShowCustomAlert={setShowCustomAlert}
                  setCustomAlertMessage={setCustomAlertMessage}
                  setCustomAlertColor={setCustomAlertColor}
                  selectedCampaign={dataToEdit}
                  campaignPlatforms={campaignPlatforms}
                  setCampaignPlatforms={setCampaignPlatforms}
                />
              )}
              <div className="d-flex justify-content-end mt-4">
                <Button color="danger" className="me-3" type="submit">
                  Guardar
                </Button>
                <Button className="btn btn-danger" onClick={handleConfirmClose}>
                  Cerrar
                </Button>
              </div>
            </Form>
            {numberOfCampaignPlatforms > 0 && (
              <div className="mt-4">
                <h5>Avanzado</h5>
                <CampaignSynchronizer campaign={dataToEdit.id} />
              </div>
            )}
          </CardText>
        </CardBody>
      </Card>
    </Container>
  );
}
