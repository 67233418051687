import { Card } from "reactstrap";

import "./index.scss";

type Props = {
  client: {
    id?: number;
    name: string;
    description?: string;
    rut?: string;
    logoUrl: string;
    color?: string;
    abbr?: string;
    active?: boolean;
  };
};

export default function ClientIconCard({ client }: Props) {
  let { name, logoUrl, color } = client;

  return (
    <Card style={{ border: `4px solid ${color}` }} className="ClientIconCard__Card">
      <div className="ClientIconCard__Card__Img d-flex justify-content-center align-items-center">
        <img alt={name} src={logoUrl} />
      </div>
    </Card>
  );
}
