import { Button, Col, Container, Row } from "reactstrap";
// import { formatInTimeZone } from "date-fns-tz";
import { format, utcToZonedTime } from 'date-fns-tz';

import { MdClear, MdMode } from "react-icons/md";

import { campaign, user } from "../../Views/Campaigns";

import "./index.scss";

interface CampaignCardProps {
  campaign: campaign;
  users: user[];
  setFormShow: (value: React.SetStateAction<boolean>) => void;
  setDataToEdit: (campaign: campaign) => void;
  deleteData: (id: number | undefined, name: string) => void;
}

export default function CampaignCard({
  campaign,
  users,
  setFormShow,
  setDataToEdit,
  deleteData
}: CampaignCardProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const formattedCampaign = {
      ...campaign,
      startAt:
        campaign.startAt &&
        new Date(Number(campaign.startAt)).toISOString().split("T")[0],
      endAt:
        campaign.endAt &&
        new Date(Number(campaign.endAt)).toISOString().split("T")[0],
    };

    setDataToEdit(formattedCampaign);
    setFormShow((current) => !current);
    scrollToTop();
  };

  const formatDate = (dateReceived: string) => {
    const milliseconds = parseInt(dateReceived);
    const date = new Date(milliseconds);
    const zonedDate = utcToZonedTime(date, 'Etc/GMT');
    const formattedDate = format(zonedDate, "dd-MM-yyyy");
    return formattedDate;
  };

  const getOptimizerName = () => {
    if (campaign.optimizerId) {
      const optimizer = users.find((user) => user.id === campaign.optimizerId);
      return optimizer ? `${optimizer.firstName} ${optimizer.lastName}` : null;
    }
    return null;
  };

  const handleDelete = () => {
    // Llama a la función deleteData del componente padre (Clients)
    deleteData(campaign.id, campaign.name);
  };

  return (
    <div className="CampaignCard">
      <Container className="mx-0 CampaignCard__Item">
        <Row>
          <Col md={2} className="pb-0 ps-3 fs-6 fw-bold">
            {campaign.name && campaign.name}
          </Col>

          <Col md={2} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">
                $
                {`${campaign.budget}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </div>
            </div>
          </Col>

          <Col md={2} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">
                {campaign.monthlyBudget ? "Mensual" : "Spot"}
              </div>
            </div>
          </Col>

          <Col md={2} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">
                {campaign.startAt && formatDate(campaign.startAt)}
              </div>
            </div>
          </Col>

          <Col md={2} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">
                {campaign.endAt && formatDate(campaign.endAt)}
              </div>
            </div>
          </Col>

          <Col md={1} className="ps-2">
            <div className="fs-6">
              <div className="pb-0">{getOptimizerName()}</div>
            </div>
          </Col>
          <Col md={1} className="ps-2">
            <div className="fw-bold">
              <div className="pb-0 mb-0 d-flex justify-content-end">
                <Button
                  outline
                  color="secondary"
                  className="border-0 CampaignCard__Button py-0 pe-0"
                  onClick={handleClick}
                >
                  <MdMode />
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="border-0 CampaignCard__Button py-0 pe-0"
                  onClick={handleDelete}
                >
                  <MdClear />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
