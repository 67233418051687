import React, { useEffect } from "react";
import { useState } from "react";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Card,
  CardBody,
  CardText,
  FormText,
} from "reactstrap";

import "./index.scss";
import { formatUri } from "../../App";
import CustomAlert from "../CustomAlert";
import { useSelector } from "react-redux";
import { platform } from "../Platforms";
import { showCustomNotification } from "../../Utils/notifications";
import { campaignPlatform } from "../CampaignForm";

const initialForm = {
  apiKey: "",
  setting: "",
  campaignId: 0,
  platformId: 0,
  clientId: 0,
} as campaignPlatform;

type Props = {
  campaignPlatformToEdit: campaignPlatform;
  setCampaignPlatformToEdit: (campaignPlatform: campaignPlatform) => void;
  setcampaignPlatformformShow: (value: React.SetStateAction<boolean>) => void;
  handleUpdateCampaignPlatform: (campaignPlatform: campaignPlatform) => void;
  selectedClient: number | undefined;
  selectedCampaignId: number | undefined;
  selectedPlatform: platform | undefined;
};

const validateApikey = (apiKey: string) => {
  if (!apiKey) {
    return "El ID es obligatorio";
  }
  return "";
};

export default function CampaignPlatformForm({
  campaignPlatformToEdit,
  setCampaignPlatformToEdit,
  setcampaignPlatformformShow,
  handleUpdateCampaignPlatform,
  selectedClient,
  selectedCampaignId,
  selectedPlatform,
}: Props) {
  const [form, setForm] = useState(initialForm);

  // Validaciones
  const [formErrors, setFormErrors] = useState<Partial<campaignPlatform>>({});

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  const { token } = useSelector((state: any) => state);

  useEffect(() => {
    if (Object.keys(campaignPlatformToEdit).length !== 0) {
      setForm(campaignPlatformToEdit);
    } else {
      setForm(initialForm);
    }
  }, [campaignPlatformToEdit]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (selectedCampaignId && selectedClient && selectedPlatform?.id) {
      setForm({
        ...form,
        [name]: value,
        campaignId: selectedCampaignId,
        clientId: selectedClient,
        platformId: selectedPlatform.id,
      });
    } else {
      return null;
    }
  };

  const handleConfirm = (message: string) => {
    return window.confirm(message);
  };

  const handleConfirmClose = () => {
    const hasChanges = Object.keys(form).some((key) => {
      return (
        form[key as keyof campaignPlatform] !==
        initialForm[key as keyof campaignPlatform]
      );
    });

    if (hasChanges) {
      const confirmationMessage =
        "¿Estás seguro de que deseas cerrar sin guardar los cambios?";
      if (handleConfirm(confirmationMessage)) {
        handleReset();
      }
    } else {
      handleReset();
    }
  };

  const savePlatformCampaing = async () => {

    // Validaciones antes de enviar los datos al servidor
    const apikeyError = validateApikey(form.apiKey);

    if (apikeyError) {
      // Si hay errores de validación, muestra las alertas
      showCustomNotification(
        "Por favor, completa el campo obligatorio.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      setFormErrors({
        apiKey: apikeyError,
      });
      return;
    }

    try {
      const apiUrl = `${formatUri("http")}/campaingplatforms-api`;
      const method = campaignPlatformToEdit.id ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (campaignPlatformToEdit.id) {
          handleUpdateCampaignPlatform(form);
          setcampaignPlatformformShow(false);
        } else {
          handleUpdateCampaignPlatform(data);
          setcampaignPlatformformShow(false);
        }
      } else {
        showCustomNotification(
          "Error: No fue posible guardar los cambios",
          "danger",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      }
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      showCustomNotification(
        "Error: No fue posible guardar los cambios",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
    }
  };

  const handleReset = () => {
    setForm(initialForm);
    setCampaignPlatformToEdit(initialForm);
    setcampaignPlatformformShow((current) => !current);
  };

  return (
    <Container fluid className="CampaignPlatformForm my-4">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Card className="CampaignPlatformForm__Card">
        <CardBody>
          <CardText>
            <Form autoComplete="off" className="Client_Form">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="apiKey">ID</Label>
                    <Input
                      name="apiKey"
                      type="text"
                      onChange={handleChange}
                      value={form.apiKey}
                      onFocus={() => {
                        setShowCustomAlert(false);
                        formErrors.apiKey = undefined;
                      }}
                    />
                    {formErrors.apiKey && (
                      <span className="text-danger">{formErrors.apiKey}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Configuración</Label>
                    <Input
                      name="setting"
                      type="text"
                      onChange={handleChange}
                      value={form.setting}
                    />
                    <FormText>
                      Ingresa un objeto válido o déjalo en blanco. Ej.{" "}
                      {'{ "filter": "campaign" }'}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex mt-4">
                <Button
                  color="dark"
                  outline
                  className="me-3"
                  onClick={savePlatformCampaing}
                >
                  Aceptar
                </Button>
                <Button
                  color="dark"
                  outline
                  className="me-3"
                  onClick={handleConfirmClose}
                >
                  Cerrar
                </Button>
              </div>
            </Form>
          </CardText>
        </CardBody>
      </Card>
    </Container>
  );
}
