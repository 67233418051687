import { gql } from "@apollo/client";

export const GET_ALL_PLATFORMS = gql`
  query GetAllPlatforms {
    GetAllPlatforms {
      state
      platforms {
        id
        name
        description
        setting
      }
    }
  }
`;

export const GET_CAMPAIGN_PLATFORMS = gql`
  query GetCampaignPlatforms {
    GetCampaignPlatforms {
      state
      campaign_platforms {
        id
        campaignId
        platformId
        clientId
        apiKey
        setting
      }
    }
  }
`;

export const GET_GENERAL_SETTINGS = gql`
  query GetGeneralSettings {
    GetGeneralSettings {
      state
      general_settings {
        id
        key
        value
        createdAt
        updatedAt
      }
    }
  }
`;
