import { Button } from "reactstrap";
import { formatUri } from "../../App";
import { FcGoogle } from "react-icons/fc";

export default function RefreshTokenButton() {
  const createGoogleAuthLink = async () => {
    try {
      const request = await fetch(`${formatUri("http")}/createAuthLink`, {
        method: "POST",
      });
      const response = await request.json();
      window.location.href = response.url;
    } catch (error) {
      console.log("Error during login", error);
      throw new Error("Issue with Login");
    }
  };

  return (
    <div className="App">
      <Button
        className="GoogleButton"
        block
        outline
        size="md"
        onClick={createGoogleAuthLink}
      >
        <FcGoogle /> &nbsp;Obtener nuevo token de actualización
      </Button>
    </div>
  );
}
