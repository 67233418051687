import { HrProps } from "./types"

import './index.scss'

export default function Hr ({ text, color }: HrProps) {
  if (text) {
    return (
      <div className='Component__Hr'>
        <span className='Component__Hr__Span' style={{
          backgroundColor: `${color}`
        }}>{text}</span>
      </div>
    )
  }
  return (
    <hr className='Component__Hr' />
  )
}