import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'

import { DashboardCards, DropdownInline } from '../../Components' // DropdownInline,

import './index.scss'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export default function Home() {
  const { userInfo: { firstName } } = useSelector((store: any) => store)
  
  const [presetPercentage, setPresetPercentage] = useState(0)
  const [showOnlyInvestment, setShowOnlyInvestment] = useState<boolean>(false)

  return (
    <Container fluid className='Home'>
      <Row className='mb-4'>
        <Col md={6}>
          <h4>
            <small
              style={{
                // small-caps
                fontVariant: 'small-caps',
                fontSize: '1rem',
                fontWeight: 'normal',
                userSelect: 'none'
              }}
            >
              herramienta de reportes
            </small>
            <br />
            Hola {firstName}
          </h4>
        </Col>
        <Col md={4}>
          <Form className='d-flex justify-content-end Home__Checkbox'>
            <FormGroup
              check
              inline
            >
              <Input 
                type="checkbox"
                checked={showOnlyInvestment}
                onChange={(e) => setShowOnlyInvestment(e.target.checked)}
              />
              <Label check>
                Ocultar campañas sin inversión
              </Label>
            </FormGroup>
          </Form>
        </Col>
        <Col md={2}>
          <div className='mt-4 me-3 d-flex justify-content-end Home__Dropdown'>
            <DropdownInline
              value={presetPercentage}
              setValue={setPresetPercentage}
              options={[
                '7 días vs 7 días anteriores',
                '30 días vs 30 días anteriores',
                '30 días vs 1 año atrás',
              ]}
            />
          </div>
        </Col>
      </Row>
      <Row className='py-3 fs-5 fw-normal sticky-top bg-white Home__Header'>
        <Col md={{ size: 2, offset: 3 }} className='ps-1'>
          Inversión
        </Col>
        <Col md={3}>
          <Row>
            <Col md={6} className='ps-2'>Sesiones</Col>
            <Col md={6} className='ps-0'>$/Sesión</Col>
          </Row>
        </Col>
        <Col md={3}>
          <Row>
            <Col md={6} className='ps-3'>Eventos</Col>
            <Col md={6} className='ps-2'>Rendimiento</Col>
          </Row>
        </Col>
      </Row>
      <DashboardCards presetPercentage={presetPercentage} showOnlyInvestment={showOnlyInvestment} />
    </Container>
  )
}
