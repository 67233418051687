import { gql } from "@apollo/client";

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    GetAllCampaigns {
      state
      campaigns {
        id
        clientId
        name
        description
        active
        budget
        monthlyBudget
        totalInvestment
        startAt
        endAt
        optimizerId
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    GetAllUsers {
      state
      users {
        id
        firstName
        lastName
        username
        avatar
        createdAt
        updatedAt
      }
    }
  }
`
