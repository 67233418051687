import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import "./index.scss";
import {
  CustomAlert,
  Platforms,
  UserCard,
  UsersCardsHeader,
} from "../../Components";
import { GET_GENERAL_SETTINGS } from "./connection";
import { user } from "../Campaigns";
import { GET_ALL_USERS } from "../Campaigns/connection";
import RefreshTokenButton from "../../Components/RefreshTokenButton";

export interface generalSetting {
  id: number;
  key: string;
  value?: string;
}

export default function ConfigurationView() {
  const { token, userInfo } = useSelector((state: any) => state);

  const { username } = userInfo;

  const e_GetUsers = useQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const e_GetGeneralSettings = useQuery(GET_GENERAL_SETTINGS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  // Menú Tab
  const [tabSelected, setTabSelected] = useState(1);

  // Data
  const [users, setUsers] = useState<user[]>([]);
  const [generalSettings, setGeneralSettings] = useState<generalSetting[]>([]);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  useEffect(() => {
    if (e_GetUsers.data?.GetAllUsers?.state === "GET_ALL_USERS") {
      setUsers(e_GetUsers.data.GetAllUsers.users);
    }
  }, [e_GetUsers.data]);

  useEffect(() => {
    if (
      e_GetGeneralSettings.data?.GetGeneralSettings?.state ===
      "GET_GENERAL_SETTINGS"
    ) {
      setGeneralSettings(
        e_GetGeneralSettings.data.GetGeneralSettings.general_settings
      );
    }
  }, [e_GetGeneralSettings.data]);

  return (
    <Container fluid className="Configuration">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Row>
        <Col xs={{ size: 8, offset: 2 }} className="mb-4">
          <Row>
            <Col md={8}>
              <h4>
                <small
                  style={{
                    // small-caps
                    fontVariant: "small-caps",
                    fontSize: "1rem",
                    fontWeight: "normal",
                    userSelect: "none",
                  }}
                >
                  herramienta de reportes
                </small>
                <br />
                Configuración
              </h4>
            </Col>
            {username ===
              generalSettings.find((s: any) => s.key === "main_user")
                ?.value && (
              <Col md={4}>
                <RefreshTokenButton />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="mt-2 mb-4">
        <Col
          xs={{
            size: 8,
            offset: 2,
          }}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                active={tabSelected === 1}
                onClick={() => setTabSelected(1)}
              >
                Usuarios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tabSelected === 2}
                onClick={() => setTabSelected(2)}
              >
                Plataformas
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabSelected.toString()}>
            <TabPane tabId="1">
              <UsersCardsHeader />
              {users &&
                users.map((user) => {
                  return <UserCard key={user.id} user={user} />;
                })}
            </TabPane>
            <TabPane tabId="2">
              <Platforms
                setShowCustomAlert={setShowCustomAlert}
                setCustomAlertMessage={setCustomAlertMessage}
                setCustomAlertColor={setCustomAlertColor}
              />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
