import { Route, Redirect, useLocation } from 'react-router-dom'

import { connect } from 'react-redux'
import useMobile from '../Hooks/useMobile'
import { AppFrame } from '../Components'
// import AppFrame from '../Components/AppFrame'

const mapStateToProps = ({ token, isLogged }: any) => {
    return { token, isLogged }
}

const PrivateRoute = ({ component: Component, isLogged, ...rest}: any) => {
    const isMobile = useMobile()
    const { pathname } = useLocation()

    // const { userInfo } = useSelector((store: any) => store)

    return (
        <Route {...rest} render={(props: any) => {
            const stateProps = {
                isLogged,
                isMobile
            }
            return (isLogged ?
                    <AppFrame>
                        <Component {...props} {...stateProps} />
                    </AppFrame>
            : <Redirect to={`/?next=${pathname}`} />
        )}} />
    )
}

export default connect(mapStateToProps)(PrivateRoute)