import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_CLIENTS } from "../Clients/connection";
import { GET_ALL_CAMPAIGNS, GET_ALL_USERS } from "./connection";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  CampaignForm,
  CampaignsCardsHeader,
  ClientIconCard,
  CustomAlert,
} from "../../Components";
import { client } from "../Clients";

import "./index.scss";
import { MdAdd, MdKeyboardBackspace } from "react-icons/md";
import CampaignCard from "../../Components/CampaignCard";
import { formatUri } from "../../App";
import { showCustomNotification } from "../../Utils/notifications";

export interface campaign {
  id?: number;
  clientId: number;
  name: string;
  description?: string;
  active?: boolean;
  budget?: number;
  monthlyBudget?: boolean;
  totalInvestment?: number;
  startAt?: string | undefined;
  endAt?: string | undefined;
  optimizerId?: number;
}

export interface user {
  id?: number;
  firstName: string;
  lastName: string;
  username: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
}

export default function Campaigns() {
  const { token } = useSelector((state: any) => state);

  const { clientId } = useParams<{ clientId: string }>();

  const e_GetClients = useQuery(GET_ALL_CLIENTS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const e_GetCampaigns = useQuery(GET_ALL_CAMPAIGNS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const e_GetUsers = useQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Agrega un desplazamiento suave
    });
  };

  //Data
  const [clients, setClients] = useState<client[]>([]);
  const [campaigns, setCampaigns] = useState<campaign[]>([]);
  const [users, setUsers] = useState<user[]>([]);
  const [dataToEdit, setDataToEdit] = useState<campaign>({} as campaign);

  // Formulario
  const [formShow, setFormShow] = useState(false);

  // Menú Tab de campañas activas o inactivas
  const [tabSelected, setTabSelected] = useState(1);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  const selectedClient = clients.find(
    (client) => client.id === parseInt(clientId)
  );

  const history = useHistory();

  useEffect(() => {
    if (e_GetClients.data?.GetAllClients?.state === "GET_ALL_CLIENTS") {
      setClients(e_GetClients.data.GetAllClients.clients);
    }
  }, [e_GetClients.data]);

  useEffect(() => {
    if (e_GetCampaigns.data?.GetAllCampaigns?.state === "GET_ALL_CAMPAIGNS") {
      setCampaigns(e_GetCampaigns.data.GetAllCampaigns.campaigns);
    }
  }, [e_GetCampaigns.data]);

  useEffect(() => {
    if (e_GetUsers.data?.GetAllUsers?.state === "GET_ALL_USERS") {
      setUsers(e_GetUsers.data.GetAllUsers.users);
    }
  }, [e_GetUsers.data]);

  const handleChangeClient = () => {
    setFormShow(false);
    history.push(`/clients`);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDataToEdit({} as campaign);
    setFormShow((current) => !current);
  };

  // Función para filtrar campañas según el cliente seleccionado
  const getSelectedCampaigns = () => {
    if (clientId) {
      return campaigns.filter(
        (campaign) => campaign.clientId === parseInt(clientId)
      );
    }
    return [];
  };

  const getActiveCampaignsCount = () => {
    return getSelectedCampaigns().filter((campaign) => campaign.active).length;
  };

  const getInactiveCampaignsCount = () => {
    return getSelectedCampaigns().filter((campaign) => !campaign.active).length;
  };

  const handleUpdateCampaign = (updatedCampaign: campaign) => {
    // console.log(updatedCampaign);
    // Buscar el índice de la campaña en la lista actua
    if (updatedCampaign.startAt) {
      updatedCampaign.startAt =
        new Date(updatedCampaign.startAt).getTime() + "";
    }
    if (updatedCampaign.endAt) {
      updatedCampaign.endAt = new Date(updatedCampaign.endAt).getTime() + "";
    }
    const updatedIndex = campaigns.findIndex(
      (campaign: campaign) => campaign.id === updatedCampaign.id
    );

    if (updatedIndex !== -1) {
      // Si la campaña existe en la lista, reemplazarla con los datos actualizados
      const updatedCampaigns = [...campaigns];
      updatedCampaigns[updatedIndex] = updatedCampaign;
      setCampaigns(updatedCampaigns);

      showCustomNotification(
        "Campaña actualizada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } else {
      // Si la campaña no existe en la lista, agregarlo como una nueva campaña
      setCampaigns(() => [...campaigns, updatedCampaign]);
      showCustomNotification(
        "Campaña creada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  // Para actualizar la lista de campañas después de eliminar
  const updateCampaignList = (updatedCampaigns: campaign[]) => {
    setCampaigns(updatedCampaigns);
  };

  const deleteData = (id: number | undefined, name: string) => {
    let isDelete = window.confirm(`¿Estás seguro de eliminar '${name}'?`);

    if (isDelete) {
      // Llama a la función deleteCampaign para eliminar la campaña
      deleteCampaign(id);
    } else {
      return;
    }
  };

  //Eliminación de campañas
  const deleteCampaign = async (campaignId: number | undefined) => {
    try {
      if (!campaignId) {
        throw new Error("ID de la campaña no válido");
      }

      const apiUrl = `${formatUri("http")}/campaigns-api`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: campaignId }),
      });

      if (!response.ok) {
        throw new Error("Error al eliminar la campaña");
      }
      const updatedCampaigns = campaigns.filter(
        (campaign) => campaign.id !== campaignId
      );
      updateCampaignList(updatedCampaigns);
      scrollToTop();
      showCustomNotification(
        "Campaña eliminada exitosamente",
        "success",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    } catch (error) {
      console.error("Error al eliminar la campaña:", error);
      scrollToTop();
      showCustomNotification(
        "Error al eliminar la campaña",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        true
      );
    }
  };

  return (
    <Container fluid className="Campaigns">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Row>
        <Col
          xs={{ size: 8, offset: 2 }}
          className="mb-1 d-flex align-items-center"
        >
          {selectedClient && (
            <div className="me-4 my-3">
              <ClientIconCard key={clientId} client={selectedClient} />
            </div>
          )}
          <div>
            <small
              style={{
                // small-caps
                fontVariant: "small-caps",
                fontSize: "1rem",
                fontWeight: "normal",
                userSelect: "none",
              }}
            >
              herramienta de reportes
            </small>
            <br />
            <div className="d-flex align-items-baseline">
              <h4 className="me-3">Campañas</h4>
              {selectedClient && (
                <div className="d-flex">
                  <h6 className="me-3">|</h6>
                  <h6>{selectedClient.name}</h6>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={{ size: 8, offset: 2 }}>
          {selectedClient && (
            <Row>
              <Col
                xs={{ size: 6, offset: 6 }}
                className="d-flex justify-content-end"
              >
                <Button
                  className="Campaigns__ChangeClient FormFilter__Buttons__Submit me-4"
                  color="dark"
                  outline
                  onClick={handleChangeClient}
                >
                  <MdKeyboardBackspace />
                  Seleccionar otro cliente
                </Button>
                <Button
                  className="Campaigns__New FormFilter__Buttons__Submit me-2"
                  color="dark"
                  outline
                  onClick={handleClick}
                >
                  Crear campaña
                  <MdAdd />
                </Button>
              </Col>
            </Row>
          )}

          {formShow && selectedClient && (
            <CampaignForm
              dataToEdit={dataToEdit}
              setDataToEdit={setDataToEdit}
              setFormShow={setFormShow}
              handleUpdateCampaign={handleUpdateCampaign}
              users={users}
              selectedClient={selectedClient}
              campaigns={campaigns}
            />
          )}
        </Col>
      </Row>

      {/* Mostrar los nombres de las campañas */}

      {/* Si el cliente tiene campañas */}
      {selectedClient && getSelectedCampaigns().length > 0 ? (
        <Row className="mt-2 mb-4">
          <Col
            xs={{
              size: 8,
              offset: 2,
            }}
          >
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={tabSelected === 1}
                  onClick={() => setTabSelected(1)}
                >
                  Activas ({getActiveCampaignsCount()})
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={tabSelected === 2}
                  onClick={() => setTabSelected(2)}
                >
                  Inactivas ({getInactiveCampaignsCount()})
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabSelected.toString()}>
              <TabPane tabId="1">
                <CampaignsCardsHeader />
                {getSelectedCampaigns().map((campaign) => {
                  if (campaign.active) {
                    return (
                      <CampaignCard
                        key={campaign.id}
                        campaign={campaign}
                        users={users}
                        setFormShow={setFormShow}
                        setDataToEdit={setDataToEdit}
                        deleteData={deleteData}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </TabPane>
              <TabPane tabId="2">
                <CampaignsCardsHeader />
                {getSelectedCampaigns().map((campaign) => {
                  if (campaign.active === false) {
                    return (
                      <CampaignCard
                        key={campaign.id}
                        campaign={campaign}
                        users={users}
                        setFormShow={setFormShow}
                        setDataToEdit={setDataToEdit}
                        deleteData={deleteData}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      ) : /* Si el cliente no tiene campañas */
      selectedClient && getSelectedCampaigns().length === 0 ? (
        <Row className="mt-2">
          <Col
            xs={{
              size: 8,
              offset: 2,
            }}
          >
            <h6>
              <b>Sin campañas asociadas: </b>Selecciona un cliente con campañas
              o crea una campaña nueva.
            </h6>
          </Col>
        </Row>
      ) : /* Si no se ha seleccionado un cliente */
      null}
    </Container>
  );
}
