import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom';

import useMobile from '../../Hooks/useMobile';
import { LogoContingente } from '../../Assets';

import './index.scss'


export default function RequestAccess() {
  const { isMobile } = useMobile()

  return (
    <Container fluid className='RequestAccess'>
      <Row>
        <Col className='RequestAccess__Background' hidden={isMobile} xs={1} md={6} />

        <Col className='RequestAccess__Form' xs={12} md={6}>
          <Container fluid>
            {!isMobile && <Link className='RequestAccess__Form__Back' to={'/'}><FaArrowLeft /> Volver al Inicio de Sesión</Link>}
            <Row className='RequestAccess__MainRow'>
              <Col md={{
                size: 8,
                offset: 2
              }} className='RequestAccess__Form__Col'>
                <LogoContingente className='RequestAccess__Form__Col__Logotipo' />
                <h2>¿Necesitas acceso?</h2>
                <p>Si crees que necesitas acceso a esta herramienta, completa el nombre de la empresa para la cual trabajas y tu correo electrónico para que nos comuniquemos contigo.</p>

                <Form autoComplete='off' method='post'>
                  <input autoComplete="false" name="hidden" type="text" className='d-none'></input>
                  <FormGroup>
                    <Label for="businessName">
                      Empresa
                    </Label>
                    <Input
                      id="businessName"
                      name="businessName"
                      placeholder="El nombre de tu empresa"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="businessMail">
                      Correo electrónico
                    </Label>
                    <Input
                      id="businessMail"
                      name="businessMail"
                      placeholder="hola@cliente.cl"
                      type="email"
                    />
                  </FormGroup>
                  
                  <Button block color='danger' size='lg'>
                    Recuperar
                  </Button>
                  {isMobile && <Link className='RequestAccess__Form__Back' to={'/'}><FaArrowLeft /> Volver al Inicio de Sesión</Link>}
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}