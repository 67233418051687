import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  FormFeedback,
} from "reactstrap";

import "./index.scss";
import { formatUri } from "../../App";
import CustomAlert from "../CustomAlert";
import { useSelector } from "react-redux";
import { platform } from "../Platforms";
import { showCustomNotification } from "../../Utils/notifications";

const initialForm = {
  name: "",
  description: "",
  setting: "",
} as platform;

type Props = {
  dataToEdit: platform;
  setDataToEdit: (platform: platform) => void;
  setFormShow: (value: React.SetStateAction<boolean>) => void;
  handleUpdatePlatform: (platform: platform) => void;
  platforms: platform[];
};

const validateName = (name: string) => {
  if (!name) {
    return "El nombre es obligatorio";
  }
  return "";
};

const validateSetting = (setting: string) => {
  if (!setting) {
    return "La configuración es obligatoria";
  }
  return "";
};

export default function PlatformForm({
  dataToEdit,
  setDataToEdit,
  setFormShow,
  handleUpdatePlatform,
  platforms,
}: Props) {
  const [form, setForm] = useState(initialForm);

  // Validaciones
  const [formErrors, setFormErrors] = useState<Partial<platform>>({});
  const [isNameValid, setIsNameValid] = useState(true);

  // Alerta personalizada
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [customAlertColor, setCustomAlertColor] = useState("");

  const { token } = useSelector((state: any) => state);

  useEffect(() => {
    if (Object.keys(dataToEdit).length !== 0) {
      setForm(dataToEdit);
    } else {
      setForm(initialForm);
    }
  }, [dataToEdit]);

  const duplicatePlatformName = (name: string) => {
    const duplicatePlatform =
      platforms &&
      platforms.find(
        (platform) =>
          platform.id !== form.id &&
          platform.name.toLowerCase() === name.toLowerCase()
      );

    if (duplicatePlatform) {
      setIsNameValid(false);
      return false;
    } else {
      setIsNameValid(true);
      return true;
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleConfirm = (message: string) => {
    return window.confirm(message);
  };

  const handleConfirmClose = () => {
    const hasChanges = Object.keys(form).some((key) => {
      return form[key as keyof platform] !== initialForm[key as keyof platform];
    });

    if (hasChanges) {
      const confirmationMessage =
        "¿Estás seguro de que deseas cerrar sin guardar los cambios?";
      if (handleConfirm(confirmationMessage)) {
        handleReset();
      }
    } else {
      handleReset();
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validaciones antes de enviar los datos al servidor
    const nameError = validateName(form.name);
    const settingError = validateSetting(form.setting);

    if (nameError || settingError) {
      // Si hay errores de validación, muestra las alertas
      showCustomNotification(
        "Por favor, completa todos los campos obligatorios.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      setFormErrors({
        name: nameError,
        setting: settingError,
      });
      return;
    }

    if (!isNameValid) {
      showCustomNotification(
        "Por favor, ingresa un nombre válido.",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
      return;
    }

    try {
      const apiUrl = `${formatUri("http")}/platforms-api`;
      const method = dataToEdit.id ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(form),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (dataToEdit.id) {
          handleUpdatePlatform(form);
          setFormShow(false);
        } else {
          handleUpdatePlatform(data);
          setFormShow(false);
        }
      } else {
        showCustomNotification(
          "Error: No fue posible guardar los cambios",
          "danger",
          setShowCustomAlert,
          setCustomAlertMessage,
          setCustomAlertColor,
          false
        );
      }
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
      showCustomNotification(
        "Error: No fue posible guardar los cambios",
        "danger",
        setShowCustomAlert,
        setCustomAlertMessage,
        setCustomAlertColor,
        false
      );
    }
  };

  const handleReset = () => {
    setForm(initialForm);
    setDataToEdit(initialForm);
    setFormShow((current) => !current);
  };

  return (
    <Container fluid className="ClientForm my-4">
      <CustomAlert
        isOpen={showCustomAlert}
        color={customAlertColor}
        message={customAlertMessage}
        toggle={() => setShowCustomAlert(false)}
      />
      <Card className="ClientForm__Card">
        <CardBody>
          <CardTitle tag="h5" className="mb-4">
            {Object.keys(dataToEdit).length !== 0
              ? "Editar plataforma"
              : "Nueva plataforma"}
          </CardTitle>
          <CardText>
            <Form
              autoComplete="off"
              className="Client_Form"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="name">Nombre</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={form.name}
                      invalid={!isNameValid}
                      onFocus={() => {
                        setShowCustomAlert(false);
                        formErrors.name = undefined;
                      }}
                      onBlur={(e) => {
                        if (!duplicatePlatformName(e.target.value)) {
                          setIsNameValid(false);
                        }
                      }}
                    />
                    <FormFeedback>
                      Ya existe una plataforma con este nombre
                    </FormFeedback>
                    {formErrors.name && (
                      <span className="text-danger">{formErrors.name}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>Descripción</Label>
                    <Input
                      name="description"
                      type="text"
                      onChange={handleChange}
                      value={form.description}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Configuración</Label>
                    <Input
                      name="setting"
                      type="text"
                      onChange={handleChange}
                      value={form.setting}
                      onFocus={() => {
                        setShowCustomAlert(false);
                        formErrors.setting = undefined;
                      }}
                    />
                    {formErrors.setting && (
                      <span className="text-danger">{formErrors.setting}</span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Button color="danger" className="me-3" type="submit">
                  Guardar
                </Button>
                <Link
                  className="btn btn-danger"
                  role="button"
                  to="/settings"
                  onClick={handleConfirmClose}
                >
                  Cerrar
                </Link>
              </div>
            </Form>
          </CardText>
        </CardBody>
      </Card>
    </Container>
  );
}
