import { Col, Row } from "reactstrap";

import './index.scss'

export default function CampaignPlatformsCardsHeader() {
  return (
    <div className="CampaignPlatformsCardsHeader">
      <Row className="py-2 fs-6 sticky-top bg-white CampaignPlatformsCardsHeader__Item">
        <Col md={5} className="ps-4">
          ID
        </Col>
        <Col md={5} className="ps-3">
          Configuración
        </Col>
      </Row>
    </div>
  );
}
