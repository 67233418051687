export function showCustomNotification(
  message: string,
  color: string,
  setShowCustomAlert: (value: React.SetStateAction<boolean>) => void,
  setCustomAlertMessage: (value: string) => void,
  setCustomAlertColor: (value: string) => void,
  hideTime: boolean
) {
  setCustomAlertMessage(message);
  setCustomAlertColor(color);
  setShowCustomAlert(true);

  // Oculta la alerta después de 5 segundos
  if (hideTime) {
    setTimeout(() => {
      setShowCustomAlert(false);
    }, 3000);
  }
}
