import { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import './index.scss'

export interface DropdownInlineProps {
  options: string[]
  value: number
  setValue: (value: number) => void
}

export default function DropdownInline ({ options, value, setValue }: DropdownInlineProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [actualText, setActualText] = useState<string>(options[value])

  const onClick = (index: number) => {
    setActualText(options[index])
    setValue(index)
  }

  return (
    <Dropdown className="DropdownInline" isOpen={isOpen} toggle={() => {
      setIsOpen(!isOpen)
    }}>
      <DropdownToggle className="DropdownInline__Text" color="outline-dark">
        {actualText} <IoIosArrowDown />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, index) => (
          <DropdownItem key={index} disabled={option === actualText} onClick={() => onClick(index)}>
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}