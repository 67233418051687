// Librerías externas
import React from 'react'
import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// Librerías internas
import configureStore from './Store'
import { LoaderProvider } from './Library/Context/LoaderContext'
import { ContingenteRouter } from './Routes'

// Importación de estilos CSS
import './App.scss'

// Inicialización de Redux
const { persistor, store } = configureStore()

// Parametrización del Endpoint de GraphQL
export const uri = process.env.NODE_ENV === 'production' ? 'api-informes.contingente.cl' : 'localhost:4000'

/**
 * Función para crear la URL específica (WS/HTTPS) para el Endpoint de GraphQL.
 * 
 * @param {'ws'|'http'} protocol Protocolo de comunicación con el servidor
 * @returns {string} Endpoint de GraphQL
 */
export const formatUri = (protocol: 'ws' | 'http') => {
  const isProd = process.env.NODE_ENV === 'production'
  return isProd ? `${protocol}s://${uri}` : `${protocol}://${uri}`
}

// Inialización del Cliente de Apollo (Subscripciones vía Socket)
const wsLink = new WebSocketLink({
  uri: formatUri('ws'),
  options: {
    reconnect: true
  }
})

// Inicialización del Cliente de Apollo según corresponda (Subscripciones vía HTTP con `split`)
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  // @ts-ignore
  wsLink,
  createUploadLink({
    uri: `${formatUri('http')}/graphql`,
    fetch
  })
)

// Cliente de Apollo
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all'
    }
  }
})

export default function App() {
  // Estados principal de la aplicación
  const [loading, setLoading] = React.useState<boolean>(false)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <LoaderProvider value={{ loading, setLoading }}>
            {/* {loading && <LoadingScreen />}               */}
            <ContingenteRouter />
          </LoaderProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  )
}

