import { gql } from '@apollo/client'

export const GET_DASHBOARD_CLIENTS = gql`
  query DashboardListClients($DashboardListClientsInput: DashboardListClientsInput!) {
    DashboardListClients(input: $DashboardListClientsInput) {
      state
      cards {
        id
        name
        color
        investment
        investmentPercentage
        investmentComparission
        sessions
        sessionsPercentage
        sessionsComparission
        costSession
        costSessionPercentage
        costSessionComparission
        events
        eventsPercentage
        eventsComparission
        costEvent
        costEventPercentage
        costEventComparission
        table {
          name
          fields {
            name
            value
          }
        }
      }
    }
  }
`